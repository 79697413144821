import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { v4 as uuidv4 } from 'uuid';

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { State } from 'country-state-city';
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";

type DataItem = {
    value: string;
    name: string;
};

type Data = { [key: string]: DataItem };

type ValidationResult = {
    [key: string]: {
        value: string;
        error: string | null;
        name: string;
    };
};

// Customizable Area End

export const configJSON = require("./config");
export interface Props extends WithStyles {
    navigation: any;
    id: string;
    changeTab?: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    searchValue: string;
    filteredLandBuildingTable: any;
    activeStep: number;
    propertyDetails: boolean;
    errors: {
        name_of_employer: string;
        nature_of_employer: string;
        tan_of_employer: string;
        basic_salary: any,
        house_rent_allowance: any,
        leave_travel_allowance: any,
        pin_code: string;
        city: string;
        state: string;
        address_of_employer: string;
    };
    landOrBuildingPage: number;
    landBuilding: any;
    buyerDetails: any;
    landBuildingError: any;
    stepOneError: any;
    stepTwoError: any;
    stepThreeError: any;
    costImprovementDetailsAttributes: any;
    showCostImprovement: any;
    landBuildingTable: any
    landBuildingRowID: any;
    landBuildingRowData: any;
    showAlert: boolean;
    alertType: 'success' | 'error' | 'warning';
    alertMessage: string;
    isFormSubmitted: boolean;
    isDateOfSaleOpen: boolean
    isDateOfPurchaseOpen: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class LandBuildingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    saveSelfFirstID: string = ''
    saveSelfSecondID: string = '';
    saveSelfThirdID: string = '';
    saveSelfFourthID: string = '';
    costImprovementID: string = "";
    landBuildingTableID: string = "";
    landBuildingDeleteID: string = "";
    landBuildingRowDataID: string = "";
    landBuildingEditTableRowID: string = "";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            searchValue: '',
            filteredLandBuildingTable: [],
            activeStep: 0,
            propertyDetails: true,
            errors: {
                name_of_employer: "",
                nature_of_employer: "",
                tan_of_employer: "",
                basic_salary: "",
                house_rent_allowance: "",
                leave_travel_allowance: "",
                pin_code: "",
                city: "",
                state: "",
                address_of_employer: ""
            },
            landOrBuildingPage: 1,
            landBuilding: {
                description_of_asset_sold: "",
                date_of_sale: null,
                date_of_purchase: null,
                description_of_asset_sold1: "",
                total_sale_price: "",
                total_purchase_price: "",
                transfer_expenses: "",
                value_of_property_stamp_duty_paid: "",
                pincode: "",
                city: "",
                address_line: "",
                state: "",
                country: "",
                buyer_name: "",
                ownership_percentage: "",
                aadhar: "",
                pan: "",
                amount_paid: ""
            },
            buyerDetails: [],
            costImprovementDetailsAttributes: [],
            landBuildingError: {},
            stepOneError: {},
            stepTwoError: {},
            stepThreeError: {},
            showCostImprovement: false,
            landBuildingTable: null,
            landBuildingRowID: null,
            landBuildingRowData: {},
            showAlert: false,
            alertType: 'success',
            alertMessage: '',
            isFormSubmitted: false,
            isDateOfPurchaseOpen: false,
            isDateOfSaleOpen: false,
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    setLandBuildingData = (data: any) => {
        this.setState({
            landBuilding: {
                description_of_asset_sold: data.attributes.description_of_asset_sold,
                date_of_sale: data.attributes.date_of_sale,
                date_of_purchase: data.attributes.date_of_purchase,
                description_of_asset_sold1: data.attributes.description_of_asset_sold1,
                total_sale_price: Number(data.attributes.detail_of_asset.total_sale_price),
                total_purchase_price: Number(data.attributes.detail_of_asset.total_purchase_price),
                transfer_expenses: Number(data.attributes.detail_of_asset.transfer_expenses),
                value_of_property_stamp_duty_paid: Number(data.attributes.detail_of_asset.stamp_duty_paid),
                pincode: data.attributes.property_address.pincode,
                city: data.attributes.property_address.city,
                address_line: data.attributes.property_address.address_line,
                state: data.attributes.property_address.state,
                country: data.attributes.property_address.country,
            },
            buyerDetails: data.attributes.buyer_details
        })
        const buyerDetailsObj = data.attributes.buyer_details.map((data: any) => {
            return {
                id: uuidv4(),
                objID: data.id,
                buyer_name: { value: data.buyer_name, error: null, name: "buyer_name" },
                ownership_percentage: { value: data.ownership_percentage, error: null, name: "ownership_percentage" },
                aadhar: { value: data.aadhar, error: null, name: "aadhar" },
                pan: { value: data.pan, error: null, name: "pan" },
                amount_paid: { value: Number(data.amount_paid), error: null, name: "amount_paid" }
            }
        })
        this.setState({
            buyerDetails: buyerDetailsObj
        })
        const costImprovementDetails = data.attributes.detail_of_asset.cost_improvement_details.map((data: any) => {
            return {
                id: uuidv4(),
                objID: data.id,
                description: { value: data.description, error: null, name: "description" },
                amount: { value: Number(data.amount), error: null, name: "amount" }
            }
        })
        this.setState({
            costImprovementDetailsAttributes: costImprovementDetails
        })
    }
    handleFetchLandBuildingTableData = async () => {
        const header = {
            "Content-Type": "application/json",
            token: await getStorageData("authToken")
        };

        const requestMessageObj = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.landBuildingTableID = requestMessageObj.messageId;
        requestMessageObj.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageObj.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.landBuildingTableEndPoint
        );
        requestMessageObj.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessageObj.id, requestMessageObj);
        return requestMessageObj.messageId;
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.apiCallFunctions(apiRequestCallId, responseJson)
            }
        }
    }
    apiCallFunctions = async (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.landBuildingTableID) {
            this.setState({
                landBuildingTable: responseJson.data
            })
            if (responseJson.data.length > 0) {
                this.setState({
                    landOrBuildingPage: 3
                })
            }
        };
        if (apiRequestCallId === this.landBuildingRowDataID) {
            this.setLandBuildingData(responseJson.data)
            this.setState({
                landOrBuildingPage: 1,
                landBuildingRowID: responseJson.data.id,
                landBuildingRowData: responseJson.data,
                activeStep: 0
            })
            if(responseJson.data.attributes.detail_of_asset.cost_improvement_details.length > 0){
                this.setState({
                    showCostImprovement: true,
                })
            }else{
                this.setState({
                    showCostImprovement: false,
                })
            }
        }
        if (apiRequestCallId === this.costImprovementID || apiRequestCallId === this.landBuildingEditTableRowID) {
            if(responseJson && responseJson.data){
                this.handleFetchLandBuildingTableData();
                this.setState({
                    showAlert: true,
                    alertType: 'success',
                    alertMessage: "Saved Succesfully",
                    isFormSubmitted: false,
                });
            }
            this.setState({landOrBuildingPage: 3})
        }
    }

    async componentDidMount() {
        // Customizable Area Start
        this.handleFetchLandBuildingTableData()
        this.setState({
            buyerDetails: [
                {
                    id: uuidv4(),
                    objID: null,
                    buyer_name: { value: "", error: null, name: "buyer_name" },
                    ownership_percentage: { value: "", error: null, name: "ownership_percentage" },
                    aadhar: { value: "", error: null, name: "aadhar" },
                    pan: { value: "", error: null, name: "pan" },
                    amount_paid: { value: "", error: null, name: "amount_paid" }
                },
            ],
            costImprovementDetailsAttributes: [
                {
                    id: uuidv4(),
                    objID: null,
                    description: { value: "", error: null, name: "description" },
                    amount: { value: "", error: null, name: "amount" }
                }
            ]
        })
        // Customizable Area End
    }
    createBuyerDetail = (buyerDetails: any, iterator: any) => {
        const obj = {}
        for (const key in iterator) {
            if (key !== "id")
                if (key === "objID") {
                    if (iterator[key])
                        Object.assign(obj, { ["id"]: iterator[key] })
                } else {
                    Object.assign(obj, { [key]: iterator[key].value })
                }
        }
        buyerDetails.push(obj)
    }
    createCostImprovementDetails = (costImprovementDetails: any, iterator: any) => {
        const obj = {}
        for (const key in iterator) {
            if (key === "objID") {
                if (iterator[key])
                    Object.assign(obj, { ["id"]: iterator[key] })
            } else {
                Object.assign(obj, { [key]: iterator[key].value })
            }
        }
        costImprovementDetails.push(obj)
    }
    handleLandBuildingEditForm = async (id: any) => {
        this.setState({isFormSubmitted: true})
        const header = {
            "Content-Type": "application/json",
            token: await getStorageData("authToken")
        };
        const buyerDetails: any = []
        const costImprovementDetails: any = []
        for (const iterator of this.state.buyerDetails) {
            this.createBuyerDetail(buyerDetails, iterator)
        }

        for (const iterator of this.state.costImprovementDetailsAttributes) {
            this.createCostImprovementDetails(costImprovementDetails, iterator)
        }

        const finalData = {
            "capital_gain_land_building": {
                "description_of_asset_sold": this.state.landBuilding.description_of_asset_sold,
                "date_of_sale": this.state.landBuilding.date_of_sale,
                "date_of_purchase": this.state.landBuilding.date_of_purchase,
                "detail_of_asset_attributes": {
                    "id": this.state.landBuildingRowData.attributes.detail_of_asset.id,
                    "total_sale_price": this.state.landBuilding.total_sale_price,
                    "total_purchase_price": this.state.landBuilding.total_purchase_price,
                    "transfer_expenses": this.state.landBuilding.transfer_expenses,
                    "value_of_property_stamp_duty_paid": Number(this.state.landBuilding.value_of_property_stamp_duty_paid),
                    "cost_improvement_details_attributes": costImprovementDetails
                },
                "property_address_attributes": {
                    "id": this.state.landBuildingRowData.attributes.property_address.id,
                    "pincode": this.state.landBuilding.pincode,
                    "city": this.state.landBuilding.city,
                    "address_line": this.state.landBuilding.address_line,
                    "state": this.state.landBuilding.address_line,
                    "country": this.state.landBuilding.country
                },
                "buyer_details_attributes": buyerDetails
            }
        }

        const body = finalData
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.landBuildingEditTableRowID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.landBuildingTableEndPoint}/${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    }
    validatePanCard = (data: any, a: any, obj: any) => {
        if (data[a].value === "") {
            Object.assign(obj, {
                [a]: {
                    value: data[a].value,
                    error: "Required!",
                    name: data[a].name
                }
            })
        } else if (!/^[A-Z]{5}\d{4}[A-Z]/.test(data[a].value)) {
            Object.assign(obj, {
                [a]: {
                    value: data[a].value,
                    error: "Invalid PAN Card Number!",
                    name: data[a].name
                }
            })
        } else {
            Object.assign(obj, {
                [a]: {
                    value: data[a].value,
                    error: null,
                    name: data[a].name
                }
            })

        }
    }

    validateAadhar = (data: Data, key: string, obj: ValidationResult) => {
        let value = data[key].value
        if (value === "") {
            Object.assign(obj, {
                [key]: {
                    value: value,
                    error: "Required!",
                    name: data[key].name,
                }
            })
        } else if (!/^\d{12}$/.test(value)) {
            Object.assign(obj, {
                [key]: {
                    value: value,
                    error: "Please Enter a valid Number",
                    name: data[key].name,
                }
            })
        } else {
            Object.assign(obj, {
                [key]: {
                    value: value,
                    error: null,
                    name: data[key].name,
                }
            })
        }
    }

    validateStepThreeOtherField = (data: any, a: any, obj: any) => {
        if (data[a].value === "") {
            Object.assign(obj, {
                [a]: {
                    value: data[a].value,
                    error: "Required!",
                    name: data[a].name
                }
            })

        } else if (!/^\d+$/.test(data[a].value)) {
            Object.assign(obj, {
                [a]: {
                    value: data[a].value,
                    error: "Please Enter a valid Number",
                    name: data[a].name
                }
            })

        } else {
            Object.assign(obj, {
                [a]: {
                    value: data[a].value,
                    error: null,
                    name: data[a].name
                }
            })

        }
    }

    validateOwnershipPercentageFields = (data: any, a: any, obj: any) => {
        if (data[a].value === null || data[a].value === "") {
            Object.assign(obj, {
                [a]: {
                    value: data[a].value,
                    error: null,
                    name: data[a].name
                }
            });
        } else if (!/^(0*[1-9]?\d(\.\d+)?|100(\.0+)?)$/.test(data[a].value)) {
            Object.assign(obj, {
                [a]: {
                    value: data[a].value,
                    error: "Please Enter a valid Number",
                    name: data[a].name
                }
            })

        } else {
            Object.assign(obj, {
                [a]: {
                    value: data[a].value,
                    error: null,
                    name: data[a].name
                }
            })

        }
    }

    // Customizable Area Start

    validateCostImprovementAmount = (data: any, a: any, obj: any) => {
        if (!/^\d+$/.test(data[a].value) && data[a].value !== "") {
            Object.assign(obj, {
                [a]: {
                    value: data[a].value,
                    error: "Please Enter a valid Number",
                    name: "amount"
                }
            })

        } else {
            Object.assign(obj, {
                [a]: {
                    value: data[a].value,
                    error: null,
                    name: "amount"
                }
            })

        }
    }
    handleErrorObj = (newError: any, data: any) => {
        for (const iterator of data) {
            for (const s in iterator) {
                if (iterator[s]) {
                    if (iterator[s].error === "Please Enter a valid Number") {
                        newError.error = true
                        break
                    } else {
                        newError.error = false
                    }
                }
            }
            if (newError.error === true) {
                break
            }
        }
    }
    handleValidateCostImprovement = (newError: any) => {

        const data = this.state.costImprovementDetailsAttributes.map((data: any) => {
            const obj: any = {}
            for (const a in data) {
                if (a === "id" || a === "objID") {
                    Object.assign(obj, {
                        [a]: data[a]
                    })
                } else if (a === "description") {
                    Object.assign(obj, {
                        [a]: {
                            value: data[a].value,
                            error: null,
                            name: "description"
                        }
                    })

                } else if (a === "amount") {
                    this.validateCostImprovementAmount(data, a, obj)
                }
            }
            return obj
        })
        this.setState({
            costImprovementDetailsAttributes: data
        })

        this.handleErrorObj(newError, data)
    }
    handleSubmitForm = async () => {
        this.setState({isFormSubmitted: true})
        const header = {
            "Content-Type": "application/json",
            token: await getStorageData("authToken")
        };
        const buyerDetails = []
        for (const iterator of this.state.buyerDetails) {
            const obj = {}
            for (const key in iterator) {
                if (key !== "id" && key !== "objID")
                    Object.assign(obj, { [key]: iterator[key].value })
            }
            buyerDetails.push(obj)
        }

        const costImprovementDetails = []
        for (const iterator of this.state.costImprovementDetailsAttributes) {
            const obj = {}
            for (const key in iterator) {
                if (key !== "id" && key !== "objID")
                    Object.assign(obj, { [key]: iterator[key].value })
            }
            costImprovementDetails.push(obj)
        }

        const final = {
            "capital_gain_land_building": {
                "description_of_asset_sold": this.state.landBuilding.description_of_asset_sold,
                "date_of_sale": this.state.landBuilding.date_of_sale,
                "date_of_purchase": this.state.landBuilding.date_of_purchase,
                "detail_of_asset_attributes": {
                    "total_sale_price": this.state.landBuilding.total_sale_price,
                    "total_purchase_price": this.state.landBuilding.total_purchase_price,
                    "transfer_expenses": this.state.landBuilding.transfer_expenses,
                    "value_of_property_stamp_duty_paid": this.state.landBuilding.value_of_property_stamp_duty_paid,
                    "cost_improvement_details_attributes": costImprovementDetails
                },
                "property_address_attributes": {
                    "pincode": this.state.landBuilding.pincode,
                    "city": this.state.landBuilding.city,
                    "address_line": this.state.landBuilding.address_line,
                    "state": this.state.landBuilding.state,
                    "country": this.state.landBuilding.country
                },
                "buyer_details_attributes": buyerDetails
            }
        }

        const body = final
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.costImprovementID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.capitalGainLandBuildingsEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    }

    handleStepOneNavigation = (newError: any, costImprovementError: any) => {
        if (Object.keys(newError).length > 0 || costImprovementError.error) {
            this.setState({
                stepOneError: newError
            })
        } else {
            this.setState({
                activeStep: 1,
                stepOneError: newError
            })
        }
    }
    handleStepTwoNavigation = (newError: any) => {
        if (!/^[1-9]\d{2}\s?\d{3}$/.test(this.state.landBuilding.pincode) && this.state.landBuilding.pincode !== "") {
            newError.pincode = "Invalid Pincode!"
        }
        if (Object.keys(newError).length > 0) {
            this.setState({
                stepTwoError: newError
            })
        } else {
            this.setState({
                activeStep: 2,
            })
        }
    }
    handleValidateStepOne = (newError: any) => {
        if (!/^\d+$/.test(this.state.landBuilding.transfer_expenses) && this.state.landBuilding.transfer_expenses !== "") {
            newError.transfer_expenses = "Please Enter a valid Number"
        }

        if (!/^\d+$/.test(this.state.landBuilding.value_of_property_stamp_duty_paid) && this.state.landBuilding.value_of_property_stamp_duty_paid !== "") {
            newError.value_of_property_stamp_duty_paid = "Please Enter a valid Number"
        }
        if (this.state.landBuilding.total_sale_price === "") {
            newError.total_sale_price = "Required!"
        } else if (!/^\d+$/.test(this.state.landBuilding.total_sale_price)) {
            newError.total_sale_price = "Please Enter a valid Number"
        }

        if (this.state.landBuilding.total_purchase_price === "") {
            newError.total_purchase_price = "Required!"
        } else if (!/^\d+$/.test(this.state.landBuilding.total_purchase_price)) {
            newError.total_purchase_price = "Please Enter a valid Number"
        }
    }
    ValidateBeforeNavigation = (newError: any, data: any) => {
        for (const object of data) {
            for (const s in object) {
                if (object[s]) {
                    if (object[s].error === "Required!" || object[s].error === "Please Enter a valid Number") {
                        newError.error = true
                        break
                    } else {
                        newError.error = false
                    }
                }
            }
            if (newError.error === true) {
                break
            }
        }
    }
    handleValidateStepThree = (newError: any) => {
        const data = this.state.buyerDetails.map((data: any) => {
            const obj: any = {}
            for (const a in data) {
                if (a === "id" || a === "buyer_name" || a === "objID") {
                    Object.assign(obj, {
                        [a]: data[a]
                    })
                } else if (a === "ownership_percentage") {
                    this.validateOwnershipPercentageFields(data, a, obj)
                } else if (a === "pan") {
                    this.validatePanCard(data, a, obj)
                } else if(a === "aadhar"){
                    this.validateAadhar(data, a, obj)
                } else {
                    this.validateStepThreeOtherField(data, a, obj)
                }
            }
            return obj
        })
        this.ValidateBeforeNavigation(newError, data)
        this.setState({
            buyerDetails: data
        })
    }
    handleGoToNextStep = () => {
        if (this.state.activeStep === 0) {
            const newError: any = {}
            const costImprovementError: any = {}
            this.handleValidateStepOne(newError)
            this.handleValidateCostImprovement(costImprovementError)
            this.handleStepOneNavigation(newError, costImprovementError)
        } else if (this.state.activeStep === 1) {
            const newError: any = {}
            this.handleStepTwoNavigation(newError)
        } else {
            const newError: any = {}
            this.handleValidateStepThree(newError)
            if (newError.error === false) {
                if (this.state.landBuildingRowID) {
                    this.handleLandBuildingEditForm(this.state.landBuildingRowID)
                } else {
                    this.handleSubmitForm()
                }
            }
        }
    }
    handleNavigateToCapitalGainsPage = () => {
        if (this.state.landOrBuildingPage === 1) {
            if (this.state.landBuildingTable.length > 0) {
                this.setState({
                    landOrBuildingPage: 3
                })
            } else {
                this.props.changeTab("Capital Gains")
            }
        } else if (this.state.landOrBuildingPage === 3) {
            this.props.changeTab("Capital Gains")

        }
    }
    goToPrevStep = () => {
        if (this.state.activeStep > 0) {
            this.setState({
                activeStep: this.state.activeStep - 1
            })
        } else {
            this.setState({
                landOrBuildingPage: 1
            })
        }
    }
    backToStepper = () => {
        this.handleNavigateToCapitalGainsPage()
        this.setState({
            landOrBuildingPage: 2,
            landBuilding: {
                address_line: "",
                state: "",
                country: "",
                buyer_name: "",
                ownership_percentage: "",
                total_sale_price: "",
                total_purchase_price: "",
                transfer_expenses: "",
                description_of_asset_sold: "",
                date_of_sale: "",
                date_of_purchase: "",
                value_of_property_stamp_duty_paid: "",
                pincode: "",
                city: "",
                aadhar: "",
                description_of_asset_sold1: "",
                pan: "",
                amount_paid: ""
            },
            landBuildingError: {},
            buyerDetails: [],
            stepThreeError: {},
            stepOneError: {},
            stepTwoError: {},
            costImprovementDetailsAttributes: [],
        })
    }
    handleOpenAddExemptionForm = () => {
        this.setState({
            landOrBuildingPage: 4
        })
    }
    handleGoToLandAndBuildingTablePage = () => {
        this.setState({
            landOrBuildingPage: 3
        })
    }
    handleGoToAddPropertyDetailForm = () => {
        this.setState({
            landOrBuildingPage: 1,
            activeStep: 0,
            landBuilding: {
                description_of_asset_sold: "",
                date_of_sale: null,
                date_of_purchase: null,
                description_of_asset_sold1: "",
                total_sale_price: "",
                total_purchase_price: "",
                transfer_expenses: "",
                value_of_property_stamp_duty_paid: "",
                pincode: "",
                city: "",
                address_line: "",
                state: "",
                country: "",
                buyer_name: "",
                ownership_percentage: "",
                aadhar: "",
                pan: "",
                amount_paid: ""
            },
            buyerDetails: [
                {
                    id: uuidv4(),
                    objID: null,
                    buyer_name: { value: "", error: null, name: "buyer_name" },
                    ownership_percentage: { value: "", error: null, name: "ownership_percentage" },
                    aadhar: { value: "", error: null, name: "aadhar" },
                    pan: { value: "", error: null, name: "pan" },
                    amount_paid: { value: "", error: null, name: "amount_paid" }
                }
            ],
            costImprovementDetailsAttributes: [],
            showCostImprovement: false,
            isFormSubmitted: false,
        })
    }
    validateAssetSold = (value: any, name: any) => {
        if (value === "") {
            this.setState((prevState) => ({
                stepOneError: {
                    ...prevState.stepOneError,
                    [name]: "Required!"
                }
            }))
        } else {
            this.setState((prevState) => ({
                stepOneError: {
                    ...prevState.stepOneError,
                    [name]: null
                }
            }))
        }
    }

    validateSalePurchase = (value: any, name: any) => {
        if (value === "") {
            this.setState((prevState) => ({
                stepOneError: {
                    ...prevState.stepOneError,
                    [name]: "Required!"
                }
            }))
        } else if (!/^\d+$/.test(value)) {
            this.setState((prevState) => ({
                stepOneError: {
                    ...prevState.stepOneError,
                    [name]: "Please Enter a valid Number"
                }
            }))
        } else {
            this.setState((prevState) => ({
                stepOneError: {
                    ...prevState.stepOneError,
                    [name]: null
                }
            }))
        }
    }
    validateTransferAndStamp = (value: any, name: any) => {
        if (!/^\d+$/.test(value) && value !== "") {
            this.setState((prevState) => ({
                stepOneError: {
                    ...prevState.stepOneError,
                    [name]: "Please Enter a valid Number"
                }
            }))
        } else {
            this.setState((prevState) => ({
                stepOneError: {
                    ...prevState.stepOneError,
                    [name]: null
                }
            }))
        }
    }
    handleValidatePinCode = (value: any, name: any) => {
        if (!/^[1-9]\d{2}\s?\d{3}$/.test(value) && value !== "") {
            this.setState((prevState) => ({
                stepTwoError: {
                    ...prevState.stepTwoError,
                    [name]: "Invalid Pincode!"
                }
            }))
        } else {
            this.setState((prevState) => ({
                stepTwoError: {
                    ...prevState.stepTwoError,
                    [name]: null
                }
            }))
        }
    }
    handleSetLandBuildingFields = (e: any) => {
        const { value, name } = e.target;
        const fields = [
            "description_of_asset_sold",
            "description_of_asset_sold1",
            "total_sale_price",
            "total_purchase_price",
            "pincode",
            "transfer_expenses",
            "value_of_property_stamp_duty_paid",
        ]
        const requiredFields = []
        const excludeFields = [
            "city",
            "address_line",
            "state",
            "country",
        ]
        this.setState((prevState) => ({
            landBuilding: {
                ...prevState.landBuilding,
                [name]: value
            }
        }))

        if (!excludeFields.includes(name)) {
            if (name === fields[0]) {
                if (value === "") {
                    this.setState((prevState) => ({
                        landBuildingError: {
                            ...prevState.landBuildingError,
                            [name]: "Required!"
                        }
                    }))
                } else {
                    this.setState((prevState) => ({
                        landBuildingError: {
                            ...prevState.landBuildingError,
                            [name]: null
                        }
                    }))
                }
            } else if (name === fields[1]) {
                this.validateAssetSold(value, name)
            } else if (name === fields[2] || name === fields[3]) {
                this.validateSalePurchase(value, name)
            } else if (name === fields[5] || name === fields[6]) {
                this.validateTransferAndStamp(value, name)
            } else if (name === fields[4]) {
                this.handleValidatePinCode(value, name)
            }

        }
    }
    handleGetDate = (date: any, name: any) => {
        const dateFormat = (new Date(date).toLocaleDateString()).split("/")
        this.setState({
            landBuilding: {
                ...this.state.landBuilding,
                [name]: `${dateFormat[2]}-${dateFormat[0]}-${dateFormat[1]}`
            }
        })
        this.setState(prevState => ({
            landBuildingError: {
                ...prevState.landBuildingError,
                [name]: null
            }
        }))

    }
    handleSetState = (value: any, name: any) => {
        this.setState({
            landBuilding: {
                ...this.state.landBuilding,
                [name]: value
            }
        })
    }
    handleGetStateList = () => {
        const state: any = State.getStatesOfCountry("IN").map((data) => {
            return data.name
        })
        return state
    }
    handleAddPropertyAddress = () => {
        this.setState((prevState) => ({
            buyerDetails: [
                ...prevState.buyerDetails,
                {
                    id: uuidv4(),
                    objID: null,
                    buyer_name: { value: "", error: null, name: "buyer_name" },
                    ownership_percentage: { value: "", error: null, name: "ownership_percentage" },
                    aadhar: { value: "", error: null, name: "aadhar" },
                    pan: { value: "", error: null, name: "pan" },
                    amount_paid: { value: "", error: null, name: "amount_paid" }
                }
            ]
        }))
    }

    handleValidatePan = (value: any, name: any, data: any) => {
        if (value === "") {
            return { ...data, [name]: { value, error: "Required!", name } }
        } else if (!/[A-Z]{5}\d{4}[A-Z]$/.test(value)) {
            return { ...data, [name]: { value, error: "Invalid PAN Card Number!", name } }
        } else {
            return { ...data, [name]: { value, error: null, name } }
        }
    }
    handleValidateOwnershipPercentage = (value: any, name: any, data: any) => {
        if (!/^(0*[1-9]?\d(\.\d+)?|100(\.0+)?)$/.test(value) && value !== "") {
            return { ...data, [name]: { value, error: "Please Enter a valid Number", name } }
        } else {
            return { ...data, [name]: { value, error: null, name } }
        }
    }
    validateSetPropertyOtherFields = (requiredFieldsName: any, value: any, name: any, data: any) => {

        if (requiredFieldsName.includes(name)) {
            if (value === "") {
                return { ...data, [name]: { value, error: "Required!", name } }
            } else if (!/^\d+$/.test(value)) {
                return { ...data, [name]: { value, error: "Please Enter a valid Number", name } }
            } else {
                return { ...data, [name]: { value, error: null, name } }

            }

        }else if (name === "pan") {
            return this.handleValidatePan(value, name, data)
        } else if (name === "ownership_percentage") {
            return this.handleValidateOwnershipPercentage(value, name, data)
        } else if (name === "aadhar") {
            return this.aadharValidateSetProOtherFields(value, name, data)
        } else {
            if (!/^\d+$/.test(value) && value !== "") {
                return { ...data, [name]: { value, error: "Please Enter a valid Number" } }
            } else {
                return { ...data, [name]: { value, error: null } }
            }
        }
    }

    aadharValidateSetProOtherFields = (value: any, name: any, data: any) => {
        if (value === "") {
            return { ...data, [name]: { value, error: "Required!", name } };
        } else if (!/^\d{12}$/.test(value)) {
            return { ...data, [name]: { value, error: "Please Enter a valid Number", name } };
        } else {
            return { ...data, [name]: { value, error: null, name } };
        }
    }

    handleSetPropertyDetail = (e: any, id: any) => {
        const { name, value } = e.target
        const requiredFieldsName = ["amount_paid"]
        const updataObject = this.state.buyerDetails.map((data: any) => {
            if (data.id === id) {
                if (name === "buyer_name") {
                    return { ...data, [name]: { value, error: null } }
                } else {
                    return this.validateSetPropertyOtherFields(requiredFieldsName, value, name, data)
                }
            } else {
                return data
            }
        })
        this.setState({
            buyerDetails: updataObject
        })
    }
    deleteGroup = async (id: any, endPoint: any) => {
        const header = {
            "Content-Type": "application/json",
            token: await getStorageData("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.landBuildingDeleteID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${endPoint}/${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "DELETE"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;

    }
    handleDeletePropertyAddress = (id: any, objID: any) => {
        const filterObject = this.state.buyerDetails.filter((data: any) => data.id !== id)
        this.setState({
            buyerDetails: filterObject
        })
        if (objID) {
            this.deleteGroup(objID, configJSON.buyerDetailsDeleteEndPoint)
        }
    }
    handleDeleteCostImprovement = (id: any, objID: any) => {
        const filterObject = this.state.costImprovementDetailsAttributes.filter((data: any) => data.id !== id)
        this.setState({
            costImprovementDetailsAttributes: filterObject
        })
        if (objID) {
            this.deleteGroup(objID, configJSON.costImprovementDeleteEndPoint)
        }
    }
    handleSetCostImprovement = (e: any, id: any) => {
        const { name, value } = e.target

        const updataObject = this.state.costImprovementDetailsAttributes.map((data: any) => {
            if (data.id === id) {
                if (name === "description") {
                    return { ...data, [name]: { value, error: null, name: "description" } }
                } else if (name === "amount") {
                    if (!/^\d+$/.test(value) && value !== "") {
                        return { ...data, [name]: { value, error: "Please Enter a valid Number", name: "amount" } }
                    } else {
                        return { ...data, [name]: { value, error: null, name: "amount" } }
                    }
                }
            } else {
                return data
            }
        })
        this.setState({
            costImprovementDetailsAttributes: updataObject
        })
    }
    toggleCostInprovement = (e: any) => {
        this.setState({
            showCostImprovement: e.target.checked
        })
        if (!e.target.checked) {
            this.setState({
                costImprovementDetailsAttributes: [
                    {
                        id: uuidv4(),
                        objID: null,
                        description: { value: "", error: null, name: "description" },
                        amount: { value: "", error: null, name: "amount" }
                    }
                ]
            })
        }
    }

    handleDeleteLandBuildingRow = async (id: any) => {
        const filterArray = this.state.landBuildingTable.filter((data: any) => data.id !== id)
        this.setState({
            landBuildingTable: filterArray
        })
        const header = {
            "Content-Type": "application/json",
            token: await getStorageData("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.landBuildingDeleteID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.landBuildingTableEndPoint}/${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "DELETE"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;

    }

    handleGetLandBuildingDataByID = async (id: any) => {

        const header = {
            "Content-Type": "application/json",
            token: await getStorageData("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.landBuildingRowDataID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.landBuildingTableEndPoint}/${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;

    }
    getValidNumber = (value: any) => {
        return value.split(".")[0]
    }
    handleValidateAddPropertyDetailFields = (newError: any) => {
        if (this.state.landBuilding.description_of_asset_sold === "") {
            newError.description_of_asset_sold = "Required!"
        }
        this.handleDateValidation(newError);
    }

    handleDateValidation = (newError: any) => {
        const { date_of_sale, date_of_purchase } = this.state.landBuilding;
    
        if (!date_of_sale) {
            newError.date_of_sale = "Required!";
        }
        if (!date_of_purchase) {
            newError.date_of_purchase = "Required!";
        }
    
        if (date_of_sale && date_of_purchase) {
            if (date_of_sale <= date_of_purchase) {
                newError.date_of_sale = 'Sale date must be after purchase date';
            }
            else if (date_of_purchase >= date_of_sale) {
                newError.date_of_purchase = 'Purchase date must be before sale date';
            }
        }
    };

    submitLandBuildingForm = () => {
        const newError: any = {}
        this.handleValidateAddPropertyDetailFields(newError)
        if (Object.keys(newError).length > 0) {
            this.setState({
                landBuildingError: newError
            })
        } else {
            this.setState({
                landOrBuildingPage: 2,
                landBuildingError: {}
            })
        }
    }
    handleAddCostImprovementDetail = () => {
        this.setState((prevState) => ({
            costImprovementDetailsAttributes: [
                ...prevState.costImprovementDetailsAttributes,
                {
                    id: uuidv4(),
                    objID: null,
                    description: { value: "", error: null, name: "description" },
                    amount: { value: "", error: null, name: "amount" }
                }
            ]
        }))
    }

    returnBuyersName = (data: { buyer_name: string }[]) => {
        return data
            .filter(buyer => buyer.buyer_name)
            .map(buyer => buyer.buyer_name)
            .join(" + ");
    }

    handleCloseAlert = () => {
        this.setState({ showAlert: false })
    }

    getFormatDate = (date: string) => {
        return date && moment(date).format("DD/MM/YYYY");
    };

    getOwnershipValue = (value: null | string) => {
        return value === null ? "" : value
    }

    getNextSubmitText = () => {
        return this.state.activeStep === 2 ? "Submit" : "Next"
    }

    getCurrentListNumber = (index: number) => {
        return index > 8 ? index + 1 : `0${index + 1}`
    }

    validateDates = (purchaseDate: any, saleDate: any, dateType: 'purchase' | 'sale') => {
        const errorMessage = dateType === 'purchase' 
            ? 'Purchase date must be before sale date'
            : 'Sale date must be after purchase date';
    
        if (purchaseDate && saleDate && ((dateType === 'purchase' && purchaseDate >= saleDate) || (dateType === 'sale' && saleDate <= purchaseDate))) {
            this.setState(prevState => ({
                landBuildingError: {
                    ...prevState.landBuildingError,
                    [dateType === 'purchase' ? 'date_of_purchase' : 'date_of_sale']: errorMessage,
                }
            }));
        } else {
            this.setState(prevState => ({
                landBuildingError: {
                    ...prevState.landBuildingError,
                    date_of_sale: null,
                    date_of_purchase: null,
                }
            }));
        }
    };
    
    handleDateChangeSale = (date: any) => {
        this.setState({
            landBuilding: {
                ...this.state.landBuilding,
                date_of_sale: moment(date),
            }
        }, () => {
            this.validateDates(this.state.landBuilding.date_of_purchase, this.state.landBuilding.date_of_sale, 'sale');
        });
    };
    
    handleDateChangePurchase = (date: any) => {
        this.setState({
            landBuilding: {
                ...this.state.landBuilding,
                date_of_purchase: moment(date),
            }
        }, () => {
            this.validateDates(this.state.landBuilding.date_of_purchase, this.state.landBuilding.date_of_sale, 'purchase');
        });
    };
    handleSearchValue = (value: string) => {
        const { landBuildingTable } = this.state;

        const filteredData = landBuildingTable.filter((item: any) => {
            const { attributes } = item;
            const buyerDetails = attributes.buyer_details || [];

            return (
                attributes.description_of_asset_sold && attributes.description_of_asset_sold.toLowerCase().includes(value.toLowerCase()) ||
                attributes.sales_consideration && attributes.sales_consideration.toString().includes(value) ||
                attributes.capital_gain && attributes.capital_gain.toString().includes(value) ||
                buyerDetails.some((buyer: any) => buyer.buyer_name?.toLowerCase().includes(value.toLowerCase()))
            );
        });

        this.setState({
            searchValue: value,
            filteredLandBuildingTable: filteredData,
        });
    }

    returnTableData = () => {
        return this.state.searchValue 
        ? this.state.filteredLandBuildingTable 
        : this.state.landBuildingTable;
    }
    // Customizable Area End
}
