import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import React from "react";

export const configJSON = require("./config");
interface GetSaleShareSecuEditResponseJson {
  data: {
      attributes: {
          security_name: string;
          purchase_cost: string;
          fmv_indexed_cost: string;
          short_long: string;
          share_mutual_funds: string;
          debt_equity: string;
          sale_consideration: string;
          capital_gain_data: string;
      };
  };
}

export interface Props {
  navigation?: any;
  id?: string;
  changeTab?: any,
  needHelp?: boolean,
  handleCloseNeedHelp?: any;
  handleGoToTable?: any;
  rowId?: any
  shareAndScurity?: any
  handleGetShareAndScurity?: any
  shareAndScurityError?: any
  handleValidateShareAndScurityForm?: any
  hanleGetShareAndScurityTableData?: any
  handleGetShareAndScurityDropDown?: any
}

interface S {
  searchValue: string;
  filteredSaleofShareAndSecuritiesTableData: any;
  SaleAndSecurityPopup: boolean,
  submit: boolean,
  activeAsset: number,
  sale_of_other_asset: any,
  reversal_of_capital_gain: any,
  pass_through_income: any,
  sale_of_other_asset_errors: any,
  reversal_of_capital_gain_errors: any,
  pass_through_income_errors: any,
  capital_gains_other_id: any,
  activeCard: any,
  showSalesOfShareManualForm: boolean,
  share_and_scurity: any,
  share_and_scurity_error: any
  alertMessageShow: any
  alertMessage: any
  alertType: any
  SaleofShareAndSecuritiesTableData: any
  rowID: any,
  uploadedFiles:any,
  errorMessage:string
}

interface SS {
  id: any;
}

export default class CapitalGainLangingPageController extends BlockComponent<
  Props,
  S,
  SS
> {

  saleOfOtherAssetID: string = ""
  reversalOfCapitalGainID: string = ""
  passThroughIncomeID: string = ""
  shareAndScurityID: string = ""
  SaleofShareAndSecuritiesTableID: string = ""
  SaleofShareAndSecuritiesDeleteID: string = ""
  SaleofShareAndSecuritiesEditID: string = ""
  fileInputRef: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.fileInputRef = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      searchValue: '',
      filteredSaleofShareAndSecuritiesTableData: [],
      uploadedFiles:[],
      errorMessage:"",
      SaleAndSecurityPopup: false,
      submit: false,
      activeAsset: 0,
      sale_of_other_asset: {
        sales_consideration: "",
        cost_of_acquisition: "",
        cost_of_acquisition_date: "",
        cost_of_improvement: "",
        cost_of_improvement_date: "",
        capital_gain: "",
        transfer_expenses:""
      },
      reversal_of_capital_gain: {
        deduction_claimed_year: "",
        deduction_claimed_section: "",
        asset_required_year: "",
        amount_utilized: "",
        amount_not_utilized: "",
        amount_deemed_as_capital_gain: ""
      },
      pass_through_income: {
        taxable_at_10_percent: "",
        taxable_at_10_percent_other_than_112A: "",
        taxable_at_20_percent: "",
        taxable_at_15_percent: "",
        taxable_at_30_percent: "",
        taxable_at_slab_rate: ""
      },
      sale_of_other_asset_errors: {},
      reversal_of_capital_gain_errors: {},
      pass_through_income_errors: {},
      capital_gains_other_id: "",
      activeCard: 0,
      showSalesOfShareManualForm: false,
      share_and_scurity: {
        security_name: "",
        purchase_cost: '',
        fmv_indexed_cost: '',
        short_long: '',
        share_mutual_funds: "",
        debt_equity: '',
        sale_consideration: "",
        capital_gain_data: ""
      },
      share_and_scurity_error: {},
      alertMessageShow: false,
      alertMessage: "",
      alertType: "",
      SaleofShareAndSecuritiesTableData: [],
      rowID: ""

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.apiCallFunctions(apiRequestCallId, responseJson)
      }
    }
  }
  apiCallFunctions = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.saleOfOtherAssetID) {
      this.setState({
        capital_gains_other_id: responseJson.data.id,
        alertMessageShow: true,
        alertType: "success",
        alertMessage: "Saved Succesfully"
      })
      await setStorageData("capital_gains_other_id", this.state.capital_gains_other_id)
    } else if (apiRequestCallId === this.reversalOfCapitalGainID) {
      this.setState({
        alertType: "success",
        alertMessageShow: true,
        alertMessage: "Saved Succesfully"
      })
    } else if (apiRequestCallId === this.passThroughIncomeID) {
      this.setState({
        alertMessageShow: true,
        alertMessage: "Saved Succesfully",
        alertType: "success",
      })
    this.props.changeTab("Capital Gains")
    } else if (apiRequestCallId === this.shareAndScurityID) {
      if(responseJson && responseJson.data){
      this.setState({
        alertMessageShow: true,
        alertType: "success",
        alertMessage: this.state.rowID === null ? "Saved Succesfully" : "Edited Succesfully"
      })
        this.handleGoToTable()
        this.hanleGetShareAndScurityTableData()
      }

    } else if (apiRequestCallId === this.SaleofShareAndSecuritiesTableID) {
      this.setState({
        SaleofShareAndSecuritiesTableData: responseJson.data || []
      })
    } else if (apiRequestCallId === this.SaleofShareAndSecuritiesDeleteID) {
      this.setState({
        alertMessageShow: true,
        alertMessage: "Deleted Succesfully",
        alertType: "success"
      })
    } else if (apiRequestCallId === this.SaleofShareAndSecuritiesEditID) {
      this.setState({
        share_and_scurity: {
          security_name: this.handleGetSaleShareSecuEditValidation(responseJson, 'security_name'),
          purchase_cost: this.handleGetSaleShareSecuEditValidation(responseJson, 'purchase_cost').split(".")[0],
          fmv_indexed_cost: this.handleGetSaleShareSecuEditValidation(responseJson, 'fmv_indexed_cost').split(".")[0],
          short_long: this.handleGetSaleShareSecuEditValidation(responseJson, 'short_long').split(".")[0],
          share_mutual_funds: this.handleGetSaleShareSecuEditValidation(responseJson, 'share_mutual_funds').split(".")[0],
          debt_equity: this.handleGetSaleShareSecuEditValidation(responseJson, 'debt_equity').split(".")[0],
          sale_consideration: this.handleGetSaleShareSecuEditValidation(responseJson, 'sale_consideration').split(".")[0],
          capital_gain_data: this.handleGetSaleShareSecuEditValidation(responseJson, 'capital_gain_data').split(".")[0],
        }
      })

    }
  }

  handleGetSaleShareSecuEditValidation = (
    responseJson: GetSaleShareSecuEditResponseJson,
    value: keyof GetSaleShareSecuEditResponseJson["data"]["attributes"]
  ): string => {
      if (
          responseJson &&
          responseJson.data &&
          responseJson.data.attributes &&
          responseJson.data.attributes[value]
      ) {
          return responseJson.data.attributes[value];
      }
      return "";
  };


  handleSaleAndSecurityPopup = () => {
    this.setState({ SaleAndSecurityPopup: !this.state.SaleAndSecurityPopup });
  };
  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.hanleGetShareAndScurityTableData()
  }
  isFormFilled = (newErrors: any, state: any) => {
    newErrors.error = false
    for (const key in state) {
      if (state[key]) {
        if (state[key].length > 0) {
          delete newErrors.error
          break
        }
      }
    }
  }
  getValidNumber = (value: any) => {
    if (value === "" || value === null) {
      return ""
    } else {
      return Number(value)
    }
  }


  handOpenNeedHelpPopup = () => {
    this.props.changeTab("Capital gains", true)
  }
  handleSubmitNeedHelpForm = () => {
    this.props.handleCloseNeedHelp()
    this.setState({
      submit: true
    })
  }
  handleSetActiveAsset = (asset: number) => {
    if (this.state.activeAsset === 0 && asset === 0) {
      this.props.changeTab("Capital Gains")
      return
    }
    this.setState({
      activeAsset: asset,
      sale_of_other_asset: {
        sales_consideration: "",
        cost_of_acquisition: "",
        cost_of_acquisition_date: "",
        cost_of_improvement: "",
        cost_of_improvement_date: "",
        capital_gain: "",
        transfer_expenses:""
      },
      reversal_of_capital_gain: {
        deduction_claimed_year: "",
        deduction_claimed_section: "",
        asset_required_year: "",
        amount_utilized: "",
        amount_not_utilized: "",
        amount_deemed_as_capital_gain: ""
      },
      pass_through_income: {
        taxable_at_10_percent: "",
        taxable_at_10_percent_other_than_112A: "",
        taxable_at_20_percent: "",
        taxable_at_15_percent: "",
        taxable_at_30_percent: "",
        taxable_at_slab_rate: ""
      },
    })
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (asset === 0) {
      this.setState({
        sale_of_other_asset_errors: {},
        reversal_of_capital_gain_errors: {},
        sale_of_other_asset: {
          sales_consideration: "",
          cost_of_acquisition: "",
          cost_of_improvement: "",
          capital_gain: ""
        },
        reversal_of_capital_gain: {
          deduction_claimed_year: "",
          deduction_claimed_section: "",
          asset_required_year: "",
          amount_utilized: "",
          amount_not_utilized: "",
          amount_deemed_as_capital_gain: ""
        },
      })
    }
    this.props.changeTab("Capitalgains/OtherAssets")
  }

  handleGetDate = (date: any, name: any) => {
    if (name === "asset_required_year") {
      this.setState({
        reversal_of_capital_gain: {
          ...this.state.reversal_of_capital_gain,
          asset_required_year: new Date(date).toLocaleDateString()
        }
      })
      this.setState(prevState => ({
        reversal_of_capital_gain_errors: {
          ...prevState.reversal_of_capital_gain_errors,
          asset_required_year: null
        }
      }))
    } else {
      this.setState(prevState => ({
        sale_of_other_asset: {
          ...prevState.sale_of_other_asset,
          [name]: new Date(date).toLocaleDateString()
        }
      }))
      this.setState(prevState => ({
        sale_of_other_asset_errors: {
          ...prevState.sale_of_other_asset_errors,
          [name]: null
        }
      }))
    }
  }
  handleGetDeductionClaimedYear = (value: any) => {
    this.setState({
      reversal_of_capital_gain: {
        ...this.state.reversal_of_capital_gain,
        deduction_claimed_year: value
      }
    })
  }
  handleGetDeductionClaimedSection = (value: any) => {
    this.setState({
      reversal_of_capital_gain: {
        ...this.state.reversal_of_capital_gain,
        deduction_claimed_section: value
      }
    })
  }


  handleLongTerm = (newErrors: any) => {
    if (!/^\d+$/.test(this.state.pass_through_income.taxable_at_10_percent || this.state.pass_through_income.taxable_at_10_percent === null) && this.state.pass_through_income.taxable_at_10_percent !== "") {
      newErrors.taxable_at_10_percent = 'Please Enter a valid Number';
    }
    if (!/^\d+$/.test(this.state.pass_through_income.taxable_at_10_percent_other_than_112A || this.state.pass_through_income.taxable_at_10_percent_other_than_112A === null) && this.state.pass_through_income.taxable_at_10_percent_other_than_112A !== "") {
      newErrors.taxable_at_10_percent_other_than_112A = 'Please Enter a valid Number';
    }
    if (!/^\d+$/.test(this.state.pass_through_income.taxable_at_20_percent || this.state.pass_through_income.taxable_at_20_percent === null) && this.state.pass_through_income.taxable_at_20_percent !== "") {
      newErrors.taxable_at_20_percent = 'Please Enter a valid Number';
    }
  }
  handleShortTerm = (newErrors: any) => {
    if (!/^\d+$/.test(this.state.pass_through_income.taxable_at_15_percent || this.state.pass_through_income.taxable_at_15_percent === null) && this.state.pass_through_income.taxable_at_15_percent !== "") {
      newErrors.taxable_at_15_percent = 'Please Enter a valid Number';
    }
    if (!/^\d+$/.test(this.state.pass_through_income.taxable_at_30_percent || this.state.pass_through_income.taxable_at_30_percent === null) && this.state.pass_through_income.taxable_at_30_percent !== "") {
      newErrors.taxable_at_30_percent = 'Please Enter a valid Number';
    }
    if (!/^\d+$/.test(this.state.pass_through_income.taxable_at_slab_rate || this.state.pass_through_income.taxable_at_slab_rate === null) && this.state.pass_through_income.taxable_at_slab_rate !== "") {
      newErrors.taxable_at_slab_rate = 'Please Enter a valid Number';
    }
  }
  
  
  handleSetForm1Value = (e: any) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      sale_of_other_asset: {
        ...prevState.sale_of_other_asset,
        [name]: value
      }
    }))
    if (!/^\d+$/.test(value) && value !== "") {
      this.setState(prevState => ({
        sale_of_other_asset_errors: {
          ...prevState.sale_of_other_asset_errors,
          [name]: "Please Enter a valid Number"
        }
      }))
    } else {
      this.setState(prevState => ({
        sale_of_other_asset_errors: {
          ...prevState.sale_of_other_asset_errors,
          [name]: null
        }
      }))
    }
  }
  handleSetForm2Value = (e: any) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      reversal_of_capital_gain: {
        ...prevState.reversal_of_capital_gain,
        [name]: value
      }
    }))
    if (!/^\d+$/.test(value) && value !== "") {
      this.setState(prevState => ({
        reversal_of_capital_gain_errors: {
          ...prevState.reversal_of_capital_gain_errors,
          [name]: "Please Enter a valid Number"
        }
      }))
    } else {
      this.setState(prevState => ({
        reversal_of_capital_gain_errors: {
          ...prevState.reversal_of_capital_gain_errors,
          [name]: null
        }
      }))
    }
  }
  handleSetForm3Value = (e: any) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      pass_through_income: {
        ...prevState.pass_through_income,
        [name]: value
      }
    }))
    if (!/^\d+$/.test(value) && value !== "") {
      this.setState(prevState => ({
        pass_through_income_errors: {
          ...prevState.pass_through_income_errors,
          [name]: "Please Enter a valid Number"
        }
      }))
    } else {
      this.setState(prevState => ({
        pass_through_income_errors: {
          ...prevState.pass_through_income_errors,
          [name]: null
        }
      }))
    }
  }
  hanleSubmitSaleofOtherAssetForm = async (data: any) => {
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("authToken")
    };
    const body = {
      "capital_gains_other": {
        "step": 1,
        "sale_of_other_asset": {
          "sales_consideration": this.getValidNumber(data.sales_consideration),
          "cost_of_acquisition": `${data.cost_of_acquisition} ${data.cost_of_acquisition_date}`,
          "cost_of_improvement": `${data.cost_of_improvement} ${data.cost_of_improvement_date}`,
          "capital_gain": this.getValidNumber(data.sales_consideration)
        }
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saleOfOtherAssetID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.capitalGainOtherEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  hanleSubmitReversalOfCapitalGain = async (data: any) => {
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("authToken")
    };
    const body = {
      "capital_gains_other": {
        "id": await getStorageData("capital_gains_other_id"),
        "step": 2,
        "reversal_of_capital_gain": {
          ...data,
          "asset_required_year": this.state.reversal_of_capital_gain.asset_required_year.split("/")[2],
        }
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reversalOfCapitalGainID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.capitalGainOtherEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  hanleSubmitPassThroughIncome = async (data: any) => {
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("authToken")
    };

    const body = {
      "capital_gains_other": {
        "id": await getStorageData("capital_gains_other_id"),
        "step": 3,
        "pass_through_income": data
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.passThroughIncomeID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.capitalGainOtherEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleValidateForm1 = () => {
    const { sale_of_other_asset } = this.state;
    const newErrors:any = {};
  
    // Helper function to check if a value is a valid number
    const isValidNumber = (value:any) => /^\d+$/.test(value);
  
    // Validate each field
    const validateField = (fieldName:any) => {
      const value = sale_of_other_asset[fieldName];
      if (!isValidNumber(value) && (value !== "" && value !== null)) {
        newErrors[fieldName] = 'Please Enter a valid Number';
      }
    };
  
    validateField('sales_consideration');
    validateField('cost_of_acquisition');
    validateField('cost_of_improvement');
    validateField('capital_gain');
    validateField('transfer_expenses');
  
    // Check if the form is filled
    this.isFormFilled(newErrors, sale_of_other_asset);
  
    // Handle form submission or display errors
    if (Object.keys(newErrors).length === 0) {
      this.hanleSubmitSaleofOtherAssetForm(sale_of_other_asset);
    } else {
      this.setState({
        sale_of_other_asset_errors: newErrors,
        alertMessageShow: true,
        alertMessage: "At least one field is required",
        alertType: "error"
      });
    }
  };
  

  handleValidateForm2 = () => {
    const newErrors: any = {};
    if (!/^\d+$/.test(this.state.reversal_of_capital_gain.amount_utilized) && this.state.reversal_of_capital_gain.amount_utilized !== "") {
      newErrors.amount_utilized = 'Please Enter a valid Number';
    }
    if (!/^\d+$/.test(this.state.reversal_of_capital_gain.amount_not_utilized || this.state.reversal_of_capital_gain.amount_not_utilized === null) && this.state.reversal_of_capital_gain.amount_not_utilized !== "") {
      newErrors.amount_not_utilized = 'Please Enter a valid Number';
    }
    if (!/^\d+$/.test(this.state.reversal_of_capital_gain.amount_deemed_as_capital_gain || this.state.reversal_of_capital_gain.amount_deemed_as_capital_gain === null) && this.state.reversal_of_capital_gain.amount_deemed_as_capital_gain !== "") {
      newErrors.amount_deemed_as_capital_gain = 'Please Enter a valid Number';
    }
    this.isFormFilled(newErrors, this.state.reversal_of_capital_gain)
    if (Object.keys(newErrors).length === 0) {

      this.hanleSubmitReversalOfCapitalGain(this.state.reversal_of_capital_gain)
    } else if (newErrors.error === false) {
      this.setState({
        alertMessageShow: true,
        alertMessage: "Atleast one field is required",
        alertType: "error"
      })
    } else {
      this.setState({
        reversal_of_capital_gain_errors: newErrors,
      })
    }
  }
  handleValidateForm3 = () => {
    const newErrors: any = {};
    this.handleLongTerm(newErrors);
    this.handleShortTerm(newErrors);
    this.isFormFilled(newErrors, this.state.pass_through_income)
    if (Object.keys(newErrors).length === 0) {
      this.hanleSubmitPassThroughIncome(this.state.pass_through_income)
    } else if (newErrors.error === false) {
      this.setState({
        alertMessageShow: true,
        alertMessage: "Atleast one field is required",
        alertType: "error"
      })
    } else {
      this.setState({
        pass_through_income_errors: newErrors,
      })
    }
  }
  handleSubmitForm = (e: any, activeAsset: any) => {
    e.preventDefault();
    if (activeAsset === 1) {
      this.handleValidateForm1();
    } else if (activeAsset === 2) {
      this.handleValidateForm2()
    } else if (activeAsset === 3) {
      this.handleValidateForm3()
    }
  }
  handleGoToTable = () => {
    this.setState({
      showSalesOfShareManualForm: false
    })
  }

  hanleGetShareAndScurityTableData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.SaleofShareAndSecuritiesTableID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SaleofShareAndSecuritiesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  handleAddMoreItem = () => {
    this.setState({
      showSalesOfShareManualForm: true,
      rowID: null,
      share_and_scurity: {
        security_name: "",
        purchase_cost: '',
        fmv_indexed_cost: '',
        short_long: '',
        share_mutual_funds: "",
        debt_equity: '',
        sale_consideration: "",
        capital_gain_data: ""
      }
    })
  }

  handleDeleteRow = async (id: any) => {
    const filterArray = this.state.SaleofShareAndSecuritiesTableData.filter((data: any) => data.attributes.id !== id)
    this.setState({
      SaleofShareAndSecuritiesTableData: filterArray
    })
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.SaleofShareAndSecuritiesDeleteID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.SaleofShareAndSecuritiesEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;

  }
  handleEditRow = async (id: any) => {
    this.setState({
      rowID: id,
      showSalesOfShareManualForm: true
    })
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.SaleofShareAndSecuritiesEditID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.SaleofShareAndSecuritiesEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;

  }
  handleUpdateShareAndScurityErrorObj = (name: any, value: any) => {
    if (!/^\d+$/.test(value) && value !== "") {
      this.setState(prevState => ({
        share_and_scurity_error: {
          ...prevState.share_and_scurity_error,
          [name]: "Please Enter a valid Number"
        }
      }))
    } else {
      this.setState(prevState => ({
        share_and_scurity_error: {
          ...prevState.share_and_scurity_error,
          [name]: null
        }
      }))
    }
  }
  handleGetShareAndScurity = (e: any) => {
    const { value, name } = e.target;
    this.setState(prevState => ({
      share_and_scurity: {
        ...prevState.share_and_scurity,
        [name]: value
      }
    }))
    if (name !== "security_name") {
      this.handleUpdateShareAndScurityErrorObj(name, value)
    }
  }

  hanleSubmitShareAndScurityForm = async (id: any) => {
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("authToken")
    };
    const body = {
      "share_and_scurity": {
        "security_name": this.state.share_and_scurity.security_name,
        "purchase_cost": this.getValidNumber(this.state.share_and_scurity.purchase_cost),
        "fmv_indexed_cost": this.getValidNumber(this.state.share_and_scurity.fmv_indexed_cost),
        "short_long": this.state.share_and_scurity.short_long,
        "share_mutual_funds": this.state.share_and_scurity.share_mutual_funds,
        "debt_equity": this.state.share_and_scurity.debt_equity,
        "sale_consideration": this.getValidNumber(this.state.share_and_scurity.sale_consideration),
        "capital_gain_data": this.getValidNumber(this.state.share_and_scurity.capital_gain_data)
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.shareAndScurityID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      id === null ? configJSON.createManualEndPoint : `${configJSON.SaleofShareAndSecuritiesEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      id === null ? "POST" : "PATCH"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleValidateSecurityName = (newErrors: any) => {
    if (this.state.share_and_scurity.security_name === "") {
      newErrors.security_name = 'Required!';
    }
  }
  handleValidatePurchaseCost = (newErrors: any) => {
    if (!/^\d+$/.test(this.state.share_and_scurity.purchase_cost) && this.state.share_and_scurity.purchase_cost !== "") {
      newErrors.purchase_cost = 'Please Enter a valid Number';
    }
  }
  handleValidateFmvIndexedCost = (newErrors: any) => {
    if (!/^\d+$/.test(this.state.share_and_scurity.fmv_indexed_cost) && this.state.share_and_scurity.fmv_indexed_cost !== "") {
      newErrors.fmv_indexed_cost = 'Please Enter a valid Number';
    }
  }

  handleValidateShareAndScurityForm = (id = null) => {
    const newErrors: any = {};
    this.handleValidatePurchaseCost(newErrors)
    this.handleValidateFmvIndexedCost(newErrors)
    this.isFormFilled(newErrors, this.state.share_and_scurity)
    if (!/^\d+$/.test(this.state.share_and_scurity.sale_consideration) && this.state.share_and_scurity.sale_consideration !== "") {
      newErrors.sale_consideration = 'Please Enter a valid Number';
    }
    if (!/^\d+$/.test(this.state.share_and_scurity.capital_gain_data) && this.state.share_and_scurity.capital_gain_data !== "") {
      newErrors.capital_gain_data = 'Please Enter a valid Number';
    }

    if (Object.keys(newErrors).length === 0) {
      this.hanleSubmitShareAndScurityForm(id)
    } else if (newErrors.error === false) {
      this.setState({
        alertMessageShow: true,
        alertMessage: "Atleast one field is required",
        alertType: "error"
      })
    } else {
      this.setState({
        share_and_scurity_error: newErrors,
        alertMessageShow: true,
        alertMessage: "Some information is missing or incorrect",
        alertType: "error"
      })
    }
  }
  handleCloseMessageAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ alertMessageShow: false })
  };
  handleGetShareAndScurityDropDown = (value: any, name: any, options: any[]) => {
    this.setState(prevState => ({
      share_and_scurity: {
        ...prevState.share_and_scurity,
        [name]: value
      }
    }))
  }

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files as FileList);
    const validFormats = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const newFiles: File[] = [];

    for (const file of files) {
      if (validFormats.includes(file.type)) {
        newFiles.push(file);
      } else {
        this.setState({ errorMessage: 'Invalid file format. Please upload a CSV or XLSX file.' });
      }
    }

    this.setState((prevState) => ({
      uploadedFiles: [...prevState.uploadedFiles, ...newFiles],
      errorMessage: newFiles.length === 0 ? prevState.errorMessage : '',
    }));
  };

  triggerFileInput = () => {
    this.fileInputRef?.current?.click();
  };

  handleDeleteFile = (index: number) => {
    this.setState((prevState) => {
      const uploadedFiles = [...prevState.uploadedFiles];
      uploadedFiles.splice(index, 1);
      return { uploadedFiles };
    });
  };

  handleDownloadFile = (file: any) => {
    const url = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  handleDragOver = (event:any) => {
    event.preventDefault(); 
  };

  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const Uploadefiles = Array.from(event.dataTransfer.files);
    const formate = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const storedfiles: File[] = [];

    for (const file of Uploadefiles) {
      if (formate.includes(file.type)) {
        storedfiles.push(file);
      } else {
        this.setState({ errorMessage: 'Invalid file format. Please upload a CSV or XLSX file.' });
      }
    }

    this.setState((prevState) => ({
      uploadedFiles: [...prevState.uploadedFiles, ...storedfiles],
      errorMessage: storedfiles.length === 0 ? prevState.errorMessage : '',
    }));
  };

  handleTableDataCondition = (data: any, value: any) => {
    if (data && data.attributes && data.attributes[value]) {
      return data.attributes[value];
    }
    return "-";
  }

  handleSearchValue = (value: string) => {
    const { SaleofShareAndSecuritiesTableData } = this.state;

    const filteredData = SaleofShareAndSecuritiesTableData && SaleofShareAndSecuritiesTableData.filter((item: any) => {
      const { attributes } = item;

      return (
        attributes.security_name && attributes.security_name.toLowerCase().includes(value.toLowerCase()) ||
        attributes.purchase_cost && attributes.purchase_cost.toString().includes(value) ||
        attributes.fmv_indexed_cost && attributes.fmv_indexed_cost.toString().includes(value) ||
        attributes.sale_consideration && attributes.sale_consideration.toString().includes(value) ||
        attributes.capital_gain_data && attributes.capital_gain_data.toString().includes(value) ||
        attributes.short_long && attributes.short_long.toLowerCase().includes(value.toLowerCase()) ||
        attributes.debt_equity && attributes.debt_equity.toLowerCase().includes(value.toLowerCase()) ||
        attributes.share_mutual_funds && attributes.share_mutual_funds.toLowerCase().includes(value.toLowerCase())
      );
    });

    this.setState({
      searchValue: value,
      filteredSaleofShareAndSecuritiesTableData: filteredData,
    });
  }
}