import React from "react";
// Customizable Area Start
import LandingPageController, { Props } from "./LandingPageController.web";
import { styled } from "@material-ui/styles";
import { profileimage } from "../../user-profile-basic/src/assets";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    RadioGroup,
    Radio,
    FormControlLabel
  } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

export class TaxCalculator extends LandingPageController {
    constructor(props: Props) {
        super(props);
    }
    capitalgainPopUp=()=>{
        return(
          <>
          
          <Dialog
            data-test-id='dialog_Business'
            maxWidth='md'
            fullWidth
            open={this.state.capitalD}
            onClose={this.capitalDialogClose}
            PaperProps={{
                elevation: 0,
                style: {
                    backgroundColor: "#fff", justifyContent: 'center', width: 'auto', border: "1px solid #fff", borderRadius: "10px",
                }
            }}
            disableScrollLock={true}
          >
              <DialogTitle style={webStyle.dialogTitle} >
                 Capital Gains Calculator
                  <CloseIcon
                      data-test-id='closeDialog'
                      style={webStyle.closeBtn}
                      onClick={this.capitalDialogClose}
                  />
              </DialogTitle>
              <DialogContent style={webStyle.DailogBox}>
                  <Box>
                    <h3 style={webStyle.marginTop0}>What do you want to calculate ?</h3>
                    <RadioGroup name="capitalCalculate" style={webStyle.radioGroup} onChange={(event,value)=>this.handleRadioChange(value,"capitalCalculate")} data-test-id="saleOfLand">
                        <FormControlLabel value="SaleOfLand" control={<StyledRadio />} label="Sale of Land and Building" />
                        <FormControlLabel value="VirtualAssets" control={<StyledRadio />} label="Crypto/Virtual Digital Assets (Cryptocurrencies, NFTs and such)" />
                        <FormControlLabel value="SaleOfShares" control={<StyledRadio />} label="Sale of Shares & Securities (Sale of Indian Shares, Mutual Funds, ETFs, Bonds, Foreign Shares, ESOPs etc)" />
                    </RadioGroup>
                    <div style={webStyle.btnDiv} >
                            <button style={{ height: '45px', width: '170px', color: 'white', backgroundColor: this.state.capitalCalculate==""?'#d8d8d8':"#347152", fontSize: '16px', margin: '10px', fontWeight: "bold", borderRadius: '4px', fontFamily: 'sans-serif' }}
                            disabled={this.state.capitalCalculate==""} onClick={this.navigateToCapitalGain} data-test-id="continueBtn">Continue</button>
                    </div>
                  </Box>
              </DialogContent>
          </Dialog>
          </>
        )
      }
    render() {
        const cards = [
            {
                title: "Income Tax Calculator",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                pathname: "Assessmenttest"
            },
            {
                title: "HRA Exemption Calculator",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                pathname: "HRACalculator"
            },
            {
                title: "Capital Gains Calculator",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                pathname: "LandingPage",
                isPopup:true,
                popUpOnclick:this.capitalDialogOpen
            },
            {
                title: "Retirement Benefits Calculator",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                pathname: "LandingPage"
            },
            {
                title: "Residential Status Calculator",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                pathname: "ResidentialStatus"
            }
        ];
        return (
            <>
                <InfoWrapper>
                    <div className="backnavigation aboutIt">
                    <ChevronLeftIcon fontSize="large" onClick={() => { this.props.navigation.navigate("LandingPage") }} /><h3 >Tax Calculator</h3>
                    </div>
                    <div className="calcCards">
                        {cards.map((card: any, index) => (
                            <div className="calculatorCard" data-test-id={`card-${index}`} key={index} onClick={() => { card.isPopup ? card.popUpOnclick() : this.props.navigation.navigate(`${card.pathname}`) }}>
                                <div className="imageCalc">
                                    <img src={profileimage} alt="calc" />
                                </div>
                                <div className="aboutIt">
                                    <h1>{card.title}</h1>
                                    <p>{card.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </InfoWrapper>
                 {this.capitalgainPopUp()}
                </>
        );
    }
}

export default TaxCalculator;

const InfoWrapper = styled("div")({
    "& .backnavigation":{
        display:"flex",
        alignItems:"center",
        padding:"20px"
    },
    "& .calcCards": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        fontFamily: "Nunito",

        "& .calculatorCard": {
            cursor:"pointer",
            maxWidth: "25%",
            width: "100%",
            border: "1.5px solid gray",
            borderRadius: "7px",
            margin: "20px",
            maxHeight: "100%",
            height: "90%",
            padding: "20px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            "& .imageCalc img": {
                maxWidth: "250px",
                width: "100%",
                height: "215px"
            },
            "& .aboutIt h1": {
                fontSize: "25px",
                textAlign: "center",
                lineHeight: "62px",
                padding: "0px",
                margin: "0px"
            },
            "& .aboutIt h3": {
                fontSize: "25px",
                textAlign: "center",
                lineHeight: "62px",
                padding: "0px",
                margin: "0px"
            },
            "& .aboutIt p": {
                fontSize: "18px",
                textAlign: "center",
                padding: "0px",
                margin: "0px"
            },
            "&:hover": {
                border: "1px solid green",
                "& p": {
                    color: "green"
                },
                "& h1": {
                    color: "green"
                }
            }
        }
    }
});

const StyledRadio = styled(Radio)({
    '&.Mui-checked': {
      color: 'rgb(52, 113, 82)', // Change to your desired color
    },
  });
const webStyle = {
    DailogBox: {
        gap:'20px',
        justifyContent: 'space-evenly',
        width: '51rem',
        padding: '15px 25px',
        display: 'flex',
  },
    closeBtn: {
      top: 20,
      position: "absolute" as const,
      color: "#337152",
      right: 15,
  },
  dialogText: {
      justifyContent: 'center',
      display: 'flex',
      paddingTop: 20,
      fontFamily: 'Nunito',
      fontSize: 24,
      fontStyle: 'normal',
      fontWeight: 600,
      width: '99%',
  },
  dialogTitle: {
      position: 'relative' as const,
      display: "inline-block",
      background: '#DEF5EA',
  },
  radioGroup: {
    gap: '15px',
    display: 'flex',
  },
  marginTop0: {
    marginTop: '0'
  },
  btnDiv:{ 
    display: 'flex',
    justifyContent: 'center', 
    alignItems: 'center',
    margin: '20px' }
  }
// Customizable Area End
