import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

interface myProps {
  name: string
  onChange: any
  value: any
  placeholder: string
  type: string
  disabled: boolean
}

const useStyles = makeStyles(() => ({
  inputBox: {
    paddingLeft: '10px',
    width: '180px',
    height: '33px',
    border: '1px solid grey',
    borderRadius: '4px',
    fontSize: '14px',
  },
  disabledInput: {
    width: '180px',
    height: '33px',
    border: '1px solid #d8d8d8',
    borderRadius: '4px',
    fontSize: '18px',
    backgroundColor: '#d8d8d8',
    paddingRight: '10px'
  }

}));

const CustomInput = (props: myProps) => {
  const classes = useStyles();

  return (

    <div>
      <input className={props.disabled?classes.disabledInput:classes.inputBox}
        {...props}
      />
    </div>
  )
}
export default CustomInput;
