import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
interface ErrorMessage {
    dateOfPurchase: any;
    dateOfSale: any;
  }

// Customizable Area End

export const configJSON = require("./config");
export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    changeTab?: any,
    // Customizable Area End
}

interface S {
    // Customizable Area Start  
    error: any;
    security: any;
    typeOfSecurity: any;
    errors: {
        name_of_employer: string;
        nature_of_employer: string;
        tan_of_employer: string;
        basic_salary: any,
        house_rent_allowance: any,
        leave_travel_allowance: any,
        pin_code: string;
        city: string;
        state: string;
        address_of_employer: string;
    };
    securityFlag: boolean;
    ShowDetailForm: boolean;
    periodOfHolding: string;
    salesConsideration: string;
    units: string;
    isForcePickerOpen: boolean;
    indexedCost: any;
    selectedDate: Date;
    dateOfPurchase: any;
    SalesDatePicker:boolean;
    salesAmount: any;
    purchaseAmount: any;
    dateOfSale: any;
    bondError:ErrorMessage;
    toast: {
        type: "success" | "info" | "warning" | "error";
        open: boolean;
        message: string;
    };
    bondDetails:{
        "id": string;
        "type": string;
        "attributes": {
            "id": number;
            "pan_user_profile_id": number;
            "type_of_security": string;
            "period_of_holding": string;
            "date_of_purchase": string;
            "date_of_sale": string;
            "unit": string;
            "sales_consideration": string;
            "indexed_cost": string;
            "sale_amount": number;
            "purchase_amount":number;
        }      
    },
    bondId:string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CapGainsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    employerDetailsGet: string = ""
    CapitalGainBondsApi: string = ""
    getCapitalGainBondsDetail: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {           
        typeOfSecurity: [
            {
                key: '1', value: 'Bonds, debentures, any other securities (listed)'
            },
            {
                key: '2', value: 'Bonds, debentures, any other securities (unlisted)'
            },
            {
                key: '3', value: 'Unlisted shares'
            }],
            errors: {
                name_of_employer: "",
                nature_of_employer: "",
                tan_of_employer: "",
                basic_salary: "",
                house_rent_allowance: "",
                leave_travel_allowance: "",
                pin_code: "",
                city: "",
                state: "",
                address_of_employer: ""
            },
            toast: {
                type: "success",
                open: false,
                message: "",
            },
            error: {},
            securityFlag: false,
            security: "",
            ShowDetailForm: false,
            periodOfHolding: "",
            salesConsideration: "",
            units: "",
            isForcePickerOpen: false,
            selectedDate: new Date(),
            dateOfPurchase: null,
            dateOfSale: null,
            indexedCost: "",
            SalesDatePicker:false,
            salesAmount: "",
            purchaseAmount: "",
            bondError:{
                dateOfPurchase: "",
                dateOfSale: "",
            },
            bondDetails:{
                "id": "",
                "type": "",
                "attributes": {
                    "id": 0,
                    "pan_user_profile_id": 0,
                    "type_of_security": "",
                    "period_of_holding": "",
                    "date_of_purchase": "",
                    "date_of_sale": "",
                    "unit": "",
                    "sales_consideration": "",
                    "indexed_cost": "",
                    "sale_amount": 0,
                    "purchase_amount": 0
                }
            },
            bondId:""
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }



    async componentDidMount() {
        // Customizable Area Start
         this.getCapitalGainDetails()
        // Customizable Area End
    }



    // Customizable Area Start

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
    
          if (this.CapitalGainBondsApi === apiRequestCallId) {
            this.recieveResponse(message)
          }
          if(this.getCapitalGainBondsDetail === apiRequestCallId){
            this.recieveCapitalGainBondsDetailResponse(message)
          }
        }
        // Customizable Area End
      }

    
    getCapitalGainDetails = async() => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const getpasswordfrom = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
        this.getCapitalGainBondsDetail  = getpasswordfrom.messageId

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.capitalGainBondsEndPoint
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType

        );
        runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);
    }

    componentDidUpdate(prevProps:any, prevState:any) {
    }
    
    setPeriodOfHolding = () =>{
        const { dateOfPurchase, dateOfSale } = this.state;
        const purchaseDate = moment(dateOfPurchase, 'DD/MM/YYYY');
        const saleDate = moment(dateOfSale, 'DD/MM/YYYY');
         purchaseDate.add(1, 'days');
        const holdingPeriodInDays = saleDate.diff(purchaseDate, 'days');
        const holdingPeriod:any = Math.max(0, holdingPeriodInDays) + 1;
        this.setState({ periodOfHolding: holdingPeriod });
    }

    navigateToConsolidedEntry = () => {
        this.setState({ ShowDetailForm: true, securityFlag: false })
    }
 
    handleBlurBonds = (event: { target: { name: string, value: string } }) => {
        const { name, value } = event.target;
        this.setState((prevData) => ({
            error: {
                ...prevData,
                [name]: value.trim() === ""
            }
        }));
    }

    handleOnchangeBonds = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        let val = value.replace(/^\s+/, "").replace(/\s{2,}/g, ' ');

        if(!/^[a-zA-Z0-9 ]*$/.test(value) || value.length > 100) {
            return
        }
        this.setState((prevData) => ({
            ...prevData,
            [name]: val
        }),()=>this.getBondValidation())
    }

    handleOnchangeNumberBonds = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if(/[^0-9\b]/.test(value) || value.length > 12) {
            return
        }
        this.setState((prevData) => ({
            ...prevData,
            [name]: value
        }),()=>this.getBondValidation())
    }

    handleFocusBonds = (event: { target: { name: string, value: string } }) => {
        const { name } = event.target;
        this.setState((prevData) => ({
            error: {
                ...prevData,
                [name]: false
            }
        }));
    }
   
    setIsOpen = (flag: boolean) => {
        this.setState({ isForcePickerOpen: flag })
    }

    onChangeTypeOfSecurity = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ security: event.target.value as string })
        if (event.target.value == "Bonds, debentures, any other securities (listed)") {
            this.setState({ securityFlag: true })
        } else {
            this.setState({
                securityFlag: false,
                ShowDetailForm: false
            })
        }
    };

    getErrorMessageByName(name: string) {
        return this.state.bondError[name as keyof ErrorMessage];
    }

    validationForPurchase = (fieldName:string)=>{
        const { bondError} = this.state;
        if(this.state.dateOfSale && this.state.dateOfPurchase >= this.state.dateOfSale){
            bondError[fieldName as keyof ErrorMessage] = "Purchase date must be before sale date";
            this.setState({ periodOfHolding: "0" });
        }
         else {
            delete bondError[fieldName as keyof ErrorMessage];
        }
    }

    validateField = (fieldName:string) => {
        const { bondError} = this.state;

        if(fieldName === "dateOfPurchase"){
           this.validationForPurchase("dateOfPurchase")
        }else if(fieldName === "dateOfSale"){
            if(this.state.dateOfPurchase && this.state.dateOfSale && this.state.dateOfSale <= this.state.dateOfPurchase){
                bondError[fieldName as keyof ErrorMessage] = "Sale date must be after purchase date";
            }
             else {
                delete bondError[fieldName as keyof ErrorMessage];
            }
        }
        
        this.setState({ bondError });
      }

    getBondValidation = () => {
        let isValidate = true;
        this.validateField('dateOfPurchase');
        this.validateField('dateOfSale');
        const { bondError } = this.state;
        if (Object.keys(bondError).length > 0) {
          isValidate = false;
        }
      
        return isValidate;
      }

    handleSaveData = async () => {

        const validate = this.getBondValidation()
        if(validate){
            if(this.state.bondId){
                this.editBondDetails()
            }else{
                this.addBondDetails()
            }
        }
   }


    addBondDetails = async()=>{
        const headers = {
            "token": await getStorageData(configJSON.authToken),
            "Content-Type":"application/json",
        };

        const httpBody = 
        {
            "data": {
                "attributes": {
                    "date_of_purchase": moment(this.state.dateOfPurchase).format('DD/MM/YYYY'),
                    "type_of_security": this.state.security,
                    "date_of_sale": moment(this.state.dateOfSale).format('DD/MM/YYYY'),
                    "sales_consideration": this.state.salesConsideration,
                    "unit": this.state.units,
                    "indexed_cost": this.state.indexedCost,
                    "purchase_amount": Number(this.state.purchaseAmount),
                    "sale_amount": Number(this.state.salesAmount),
                    "period_of_holding": Number(this.state.periodOfHolding)
                }
            }
        };
        const getpasswordfrom = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.CapitalGainBondsApi = getpasswordfrom.messageId;

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.capitalGainBondsEndPoint
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod

        );
        runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);
    }

    editBondDetails = async()=>{
        const editBondheaders = {
            "Content-Type":"application/json",
            "token": await getStorageData(configJSON.authToken),
        };

        const httpRedBody = 
        {
            "data": {
                "attributes": {
                    "type_of_security": this.state.security,
                    "date_of_sale": moment(this.state.dateOfSale).format('DD/MM/YYYY'),
                    "sales_consideration": this.state.salesConsideration,
                    "purchase_amount": Number(this.state.purchaseAmount),
                    "unit": this.state.units,
                    "date_of_purchase": moment(this.state.dateOfPurchase).format('DD/MM/YYYY'),
                    "sale_amount": Number(this.state.salesAmount),
                    "indexed_cost": this.state.indexedCost,
                    "period_of_holding": Number(this.state.periodOfHolding)
                }
            }
        };
        const getEditBond = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.CapitalGainBondsApi = getEditBond.messageId;

        getEditBond.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.capitalGainBondsEndPoint}/${this.state.bondId}`
        );

        getEditBond.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(editBondheaders)
        );

        getEditBond.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpRedBody)
        );

        getEditBond.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.examplePatchAPiMethod

        );
        runEngine.sendMessage(getEditBond.id, getEditBond);
    }
   
    handleDateChangeSales= (date2: any) => {
        this.setState({ dateOfSale: moment(date2)},()=>this.getBondValidation());
     };
 
    setIsSales=(flag:boolean)=>{
        this.setState({ SalesDatePicker: flag })
    }
    handleDateChange = (date: any) => {
        this.setState({ dateOfPurchase:  moment(date)},()=>this.getBondValidation());
    };

    handleTostClose = () => {
        this.setState({
        toast: {
            open: false,
            message: "error.message",
            type: "error",
        },
        });
    }

    recieveResponse = (message: Message)=>{
        const { changeTab } = this.props;
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
        if(responseJson && responseJson?.data){  
            this.setState({
                toast: { open: true, message:"Saved successfully", type: "success" },
            });
            setTimeout(() => {
                changeTab("Capital Gains");
            }, 1500);
        }else{
            this.setState({
                toast: { open: true, message:responseJson?.error ? responseJson?.error : responseJson?.errors?.message, type: "error" },
            });
        }
    }
   
    recieveCapitalGainBondsDetailResponse = (message: Message)=>{
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
        if(responseJson && responseJson?.data?.length > 0){  
            const {type_of_security,date_of_purchase,date_of_sale,unit,sales_consideration,indexed_cost,sale_amount,purchase_amount, period_of_holding} = responseJson.data[0].attributes
            this.setState({
                periodOfHolding: period_of_holding,
                bondId:responseJson.data[0].id,
                bondDetails:responseJson.data[0],
                security:type_of_security,
                dateOfPurchase: date_of_purchase && moment(date_of_purchase),
                dateOfSale:date_of_sale && moment(date_of_sale),
                units:unit,
                salesConsideration:sales_consideration,
                indexedCost:indexed_cost,
                salesAmount:sale_amount,
                purchaseAmount:purchase_amount
            })
            if(type_of_security === "Bonds, debentures, any other securities (listed)"){
                this.setState({ShowDetailForm: true, securityFlag: false})
            }
        }
    }

    emptySelectedDate = (dateState: string) => {
        this.setState((prevState) => ({
            ...prevState,
            [dateState]: null
        }));
    };
    // Customizable Area End
}
