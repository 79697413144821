import React from "react";
import {
    // Customizable Area Start
    Container,
    Box,
    Typography,
    Theme,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { incomefrombusiness,incomefromprofession,incomefromfutures, incomefrompatnership,verysmallbusiness,regularbusiness} from "./assets";
import DashboardPanselector from "../../dashboard/src/DashboardPanselector.web";
import DailogFuturesIntraday from "./DailogFuturesIntraday.web"
import FutureOptions from "./FutureOptions.web";
import RegularBusinessIncome from "./RegularBusinessIncome.web";
import BusinessIncomeController, {
    Props, configJSON,
} from "./BusinessIncomeController.web";
import DailogPartnershipFirmCategoryWeb from "./DailogPartnershipFirmCategory.web";
import  PartnershipFirmForm  from "./PartnershipFirmForm.web";
import Toster from "../../../components/src/Toster";

// Customizable Area End
export class BusinessIncome extends BusinessIncomeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderCommonCard(title: string, subtitle: [], amount: string|number, banner: string) {
        const { classes } = this.props;
        return (
            <Box className={classes.mainCard}  >
                <Box className={classes.cardBorder}>
                    <img src={banner} className={classes.imageWidth} />
                </Box>
                <Box className={classes.textDiv}>
                    <Typography className={classes.heading11}>{title}</Typography>
                    {subtitle.map((item) => {
                        return (
                           <ul key={item + 1} className={classes.uiAlignment}>
                                <li className={classes.subLine}>{item}</li>
                            </ul> 
                        )
                    })}
                </Box>
                <Box className={classes.expandImg}>
                    <Typography>₹ {amount} </Typography>
                </Box>
            </Box>
        )
    }
    renderMultipeCards = () => {
        const { classes } = this.props;
        return (
            <Box className={classes.mainWrapper}>
                <Box className={classes.subWrapper}>
                    <Box className={classes.headerBox1}>
                        <Typography className={classes.housePropertyHdr1}> {configJSON.BusinessHeading}</Typography>
                        <Typography>{configJSON.BusinessSubHeading}</Typography>
                    </Box>
                    <Box onClick={() => this.props.changeTab("Business Income/Income From Personal")}>
                    {this.renderCommonCard(configJSON.BusinessHeading1, configJSON.incomeProfesionList, this.state.professional_or_business_income.toLocaleString(), incomefromprofession,)}
                    </Box>
                    <Box onClick={() => this.props.changeTab("Business Income/Income From Business")}>
                    {this.renderCommonCard(configJSON.BusinessHeading2, configJSON.incomeBusiness, this.state.business_income.toLocaleString(), incomefrombusiness)}
                    </Box>
                    <Box onClick={this.handleopenfuturesOrIntraday}>
                        {this.renderCommonCard(configJSON.BusinessHeading3, configJSON.incomeFeatures, this.state.income_from_futures_or_intraday.toLocaleString(), incomefromfutures)}
                    </Box>
                    <Box onClick={this.handleOpenPartnershipFirmCategory}>
                        {this.renderCommonCard(configJSON.BusinessHeading4, configJSON.incomePatnership, this.state.income_from_partnership_firms_or_playing_of_truks.toLocaleString(), incomefrompatnership)}
                    </Box>
                    <Box onClick={() => this.props.changeTab("Businessincome/SmallBusiness")}>
                        {this.renderCommonCard(configJSON.BusinessHeading5, configJSON.smallBusiness,this.state.small_business.toLocaleString(), verysmallbusiness)}
                    </Box>
                    <Box onClick={this.handleopenregularIncome}>
                        {this.renderCommonCard(configJSON.BusinessHeading6, configJSON.regularIncome, this.state.regular_business.toLocaleString(), regularbusiness)}
                    </Box>
                </Box>

            </Box>
        )
    }
    renderContent() {
        if (this.state.futuresAndOptions) {
            return (
                <FutureOptions
                    navigation={this.props.navigation}
                    id={this.props.id}
                    classes={this.props.classes}
                    open={false}
                    type={this.state.type}
                    onClick={this.handleSendData}
                />
            );
        }
        if (this.state.regularBusinessIncome) {
            return (
                <RegularBusinessIncome
                    navigation={this.props.navigation}
                    id={this.props.id}
                    classes={this.props.classes}
                    onClick={this.handleopenregularIncome}
                />
            );
        }
        if (this.state.partnershipFirmForm) {
            return (
                <PartnershipFirmForm
                    navigation={this.props.navigation}
                    id={this.props.id}
                    classes={this.props.classes}
                    open={false}
                    type={this.state.type}
                    onClick={this.handleSendDataForPartnershipFrim}
                />
            );
        }
        return this.renderMultipeCards();
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <Toster {...this.state.toast} handleTostClose={this.handleTostClose} />
                <DashboardPanselector navigation={this.props.navigation} id={""} />
                <Container className={classes.mainWrapper} maxWidth={false}>
                {this.state.futureIntraday && <DailogFuturesIntraday navigation={this.props.navigation} id={""} classes={undefined} open={this.state.futureIntraday} handleopenfuturesOrIntraday={this.handleopenfuturesOrIntraday} handleSendData={this.handleSendData} />}
                {this.state.partnershipFirmCat && <DailogPartnershipFirmCategoryWeb navigation={this.props.navigation} id={""} classes={undefined} open={this.state.partnershipFirmCat} handleopenfuturesOrIntraday={this.handleOpenPartnershipFirmCategory} handleSendData={this.handleSendDataForPartnershipFrim} />}
                    <Box className={classes.backgroundWrapper}>
                        {this.renderContent()}
                    </Box>

                </Container>
            </>
        );
    }
}
export const styles = (theme: Theme) => ({
    uiAlignment: {
        padding: "0 10px",
        margin:"7px"
    },
    subWrapper: {
        paddingBottom: 20
    },
    mainWrapper: {
        borderRadius: 16,
        background: '#F4F6F5'
    },
    cardBorder: {
        borderRadius: 12,
        background: '#F4F6F5',
        padding: '0px 45px'
    },
    imageWidth: {
        width: 160
    },
    mainCard: {
        display: 'flex',
        background: '#FFF',
        margin: 10,
        padding: 10,
        borderRadius: 16,
        position: 'relative' as const,
        [theme.breakpoints.between('xs', 'sm')]: {
            display: "unset",
        },
        cursor:"pointer"
    },
    textDiv: {
        flexDirection: 'column' as const,
        width: '100%',
        justifyContent: 'center',
        paddingLeft: 15,
        display: 'flex',
    },
    heading11: {
        color: '#36403B',
        fontStyle: 'normal',
        fontSize: 18,
        fontFamily: 'Nunito',
        fontWeight: 700,
        marginBottom:'10px'
    },
    subLine: {
        fontSize: 16,
        fontFamily: 'Nunito',
        color: 'rgba(54, 64, 59, 0.75)',
        fontWeight: 400,
        fontStyle: 'normal',
    },
    expandImg: {
        background: '#DAF0E5',
        position: 'absolute' as const,
        right: '2%',
        top: '40%',
        padding: '10 20',
        borderRadius: 10,
        [theme.breakpoints.between('xs', 'sm')]: {
            position: 'relative' as const,
        },
    },
    headerBox1: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column' as const,
        padding: '40px 25px',
    },
    housePropertyHdr1: {
        fontSize: 27,
        fontWeight: 700,
        fontStyle: 'normal',
        fontFamily: 'Nunito',
        color: '#36403B',
    },
})
export default withStyles(styles)(BusinessIncome);
// Customizable Area End

