import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
//@ts-ignore
import Aos from 'aos';
import { sendAPIRequest } from "../../../components/src/utils";
import { Message } from "../../../framework/src/Message";
import { GirlCoin, GirlDashboard, GirlStatistic } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
export const baseURL = require("../../../framework/src/config.js").baseURL;


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history:any;
  open:boolean;
  handleModelClose:any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  showVideoIcon: boolean;
  responseJourney: {
    id: string;
    attributes: {
      title: string;
      description: string;
    };
  }[];
  responseFeatures: {
    id: string;
    attributes: {
      title: string;
      description: string;
    };
  }[];
  responseTutorials: {
    id: string;
    attributes: {
      title: string;
      description: string;
      video_link: string;
      link:string;
      image: string;
      profile_photo: string;
      name: string;
    };
  }[];
  responseIntroduction: {
    id: string;
    attributes: {
      video: string;
      image: string;
    };
  }[];
  responseReview: {
    id: string;
    attributes: {
      description: string;
      profile_photo: string;
      name: string;
      rating: string;
      location: string;
    };
  }[];
  knowladgeBlog: object,
  knowladgeVideo: string,
  knowladgeImage:string,
  videoOpen: {
    open: boolean,
    video: string,
  },
  loadMore: number,
  searchInput: string;
  open: boolean;
  errors: {
    name: string;
    email: string;
    query: string;
  };
  name: string;
  email: string;
  query: string;
  snackbarOpen: boolean;
  searchknowledge:boolean;
  searchStatus:boolean;
  vedioImage:boolean;
  expanded:boolean;
  responseRecentArticles:{
    id: string;
    attributes: {
      title: string;
      description: string;
      video_url: string;
      link:string;
      image: string;
      created_at: string;
      author: string;
    };

  }[];
  responseRecentArticlesAll:{
    id: string;
    attributes: {
      title: string;
      description: string;
      video_url: string;
      link:string;
      image: string;
      created_at: string;
      author: string;
    };

  }[],
  filteredData:{
    id: string;
    attributes: {
      title: string;
      description: string;
      video_url: string;
      link:string;
      image: string;
      created_at: string;
      author: string;
    };
  }[],
  capitalD:boolean,
  capitalCalculate:string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  landingPageJourneyId: string = "";
  landingPageFeaturesId: string = "";
  landingPageTutorialsId: string = "";
  landingPageIntroductionId: string = "";
  landingPageReviewId: string = "";
  knowladgeBaseBlog: string = "";
  knowladgeBaseVideo: string = "";
  makeAnEnquiryId:string="";
  knowladgeBaseAllArticles:string="";
  getdownloadITR1:string="";
  TaxcalculatorAnalytics:string="";
  KnowledgeAnalyticsEndPoint:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    this.state = {
      // Customizable Area Start
      showVideoIcon: true,
      responseJourney: [],
      responseFeatures: [],
      responseTutorials: [],
      responseIntroduction: [],
      responseReview: [],
      responseRecentArticles:[],
      responseRecentArticlesAll:[],
      filteredData:[],
      knowladgeBlog: {},
      knowladgeVideo: "",
      knowladgeImage: "",
      videoOpen: {
        open: false,
        video: "",
      },
      loadMore: 9,
      open: false,
      errors: {
        name: "",
        email: "",
        query: "",
      },
      name: "",
      email: "",
      query: "",
      snackbarOpen: false,
      searchknowledge:false,
      searchInput: "",
      searchStatus:false,
      vedioImage:false,
      expanded:false,
      capitalD:false,
      capitalCalculate:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  journeyImage = [GirlCoin, GirlStatistic, GirlDashboard];
  colors = [configJSON.colourOne, configJSON.colourTwo, configJSON.colourThree];
  settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1090,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
         
        }
      },
      {
        breakpoint: 854,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          
        }
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
         
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (!errorResponse && apiRequestCallId && responseJson) {
      const apiHandlers = {
        [this.landingPageJourneyId]: this.handleJourneyResponse,
        [this.landingPageFeaturesId]: this.handleFeaturesResponse,
        [this.landingPageTutorialsId]: this.handleTutorialsResponse,
        [this.landingPageIntroductionId]: this.handleIntroductionResponse,
        [this.landingPageReviewId]: this.handleReviewResponse,
        [this.knowladgeBaseBlog]: this.handleBlogApiResponse,
        [this.knowladgeBaseVideo]:this.handleVideoApiResponse,
        [this.knowladgeBaseAllArticles]:this.handleAllAriicleResponse,
        [this.TaxcalculatorAnalytics]:this.handleTaxcalculatorAnalyticsResponse,
      };
   
      const handlerFunction = apiHandlers[apiRequestCallId];
      if (handlerFunction) {
        handlerFunction(responseJson);
      } 
      if (this.makeAnEnquiryId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiResponse&& apiResponse.data) {
         this.setState({name:"",email:"",query:"" ,snackbarOpen:true})
        
        }
      
      }
    }
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    Aos.init({ duration: 1500 });
    if (window.location.pathname.includes(configJSON.LandingPage) ||window.location.pathname.includes("") ) {
      this.landingPageJourneyId = sendAPIRequest(configJSON.landinPageParams + configJSON.journeyApi, {
        method: configJSON.validationApiMethodType,
        headers: {
          "Content-Type": configJSON.exampleApiContentType,
        },
      });
      this.landingPageFeaturesId = sendAPIRequest(configJSON.landinPageParams + configJSON.featuresApi, {
        method: configJSON.validationApiMethodType,
        headers: {
          "Content-Type": configJSON.exampleApiContentType,
        },
      });
      this.landingPageReviewId = sendAPIRequest(configJSON.landinPageParams + configJSON.reviewApi, {
        method: configJSON.validationApiMethodType,
        headers: {
          "Content-Type": configJSON.exampleApiContentType,
        },
      });
      this.landingPageTutorialsId = sendAPIRequest(configJSON.landinPageParams + configJSON.tutorialsApi, {
        method: configJSON.validationApiMethodType,
        headers: {
          "Content-Type": configJSON.exampleApiContentType,
        },
      });
      this.landingPageIntroductionId = sendAPIRequest(configJSON.landinPageParams + configJSON.introductionApi, {
        method: configJSON.validationApiMethodType,
        headers: {
          "Content-Type": configJSON.exampleApiContentType,
        },
      });
    }
    if (window.location.pathname.includes("Knowladgebase")) {
      this.knowladgeBaseBlog = sendAPIRequest(configJSON.knowladge_base, {
        method: configJSON.validationApiMethodType,
        headers: {
          "Content-Type": configJSON.exampleApiContentType,
        },
      });
      this.knowladgeBaseVideo = sendAPIRequest(configJSON.knowledgeVedioUrl + configJSON.knowledgeVideo, {
        method: configJSON.validationApiMethodType,
        headers: {
          "Content-Type": configJSON.exampleApiContentType,
        },
      });
      this.knowladgeBaseAllArticles = sendAPIRequest(configJSON.knowladge_baseAll , {
        method: configJSON.validationApiMethodType,
        headers: {
          "Content-Type": configJSON.exampleApiContentType,
        },
      });
    }
    if (window.location.pathname.includes("TaxCalculator")) {
    window.scrollTo(0, 0);
    }
    if (window.location.pathname === "/") {
      window.scrollTo(0, 0);
      }

  }

  handleOpenVideo = (url: string) => {
    this.setState({
      videoOpen: { video: url, open: !this.state.videoOpen.open }
    })
  }
  handleCloseVideo = () => {
    this.setState({
      videoOpen: { video: configJSON.urlVedio, open: false }
    })
  }
  //header section
  handleLoginBtn = () => {
    this.props.navigation.history.push(configJSON.login)
  }

  handleLogout = () => {
    this.props.navigation.history.push(configJSON.login)
  }

  handleHeaderRoute = (path: string) => {
    if(path == "Testimonials"){
      if(window.location.pathname === "/Knowladgebase"){
        this.props.navigation.navigate("LandingPage")
        setTimeout(() => {
          const element = document.getElementById('testimonials');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 10);
      }
      else{
        const element = document.getElementById('testimonials');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
      return
    }
    if(path == "Knowladgebase"){
      this.handleknowledgeanalytics()
    }
    this.props.navigation.history.push(path)
  }

  handleTaxcalculatorAnalyticsResponse = (responseJson:any)=>{
    this.props.navigation.navigate("TaxCalculator")
  }

  //landingpage section
  handleJourneyResponse = (responseJson: {
    data: {
      id: string;
      attributes: {
        title: string;
        description: string;
      };
    }[]
  }) => {
    this.setState({ responseJourney: [...responseJson.data] })
  }

  handleFeaturesResponse = (responseJson: {
    data: {
      id: string;
      attributes: {
        title: string;
        description: string;
      };
    }[]
  }) => {
    this.setState({ responseFeatures: [...responseJson.data] })
  }

  handleTutorialsResponse = (responseJson: {
    data: {
      id: string;
      attributes: {
        title: string;
        description: string;
        video_link: string;
        image: string;
        link:string;
        profile_photo: string;
        name: string;
      };
    }[]
  }) => {
    this.setState({ responseTutorials: [...responseJson.data] })
  }

  handleIntroductionResponse = (responseJson: {
    data: {
      id: string;
      attributes: {
        video: string;
        image: string;
      };
    }[]
  }) => {
    this.setState({ responseIntroduction: [...responseJson.data] })
  }

  handleReviewResponse = (responseJson: {
    data: {
      id: string;
      attributes: {
        description: string;
        profile_photo: string;
        name: string;
        rating: string;
        location: string;

      };
    }[]
  }) => {
    this.setState({ responseReview: [...responseJson.data] })
  }
  //knowladge base section
  addControls = () => {
    const video = document.getElementById("video-id") as HTMLVideoElement;
    if (video) {
      video.setAttribute("controls", "controls");
      video.play().catch(() => {
        // Handle any error that occurred during play()
      });
      this.setState({vedioImage:true})
    }
  }

  makeAnQuery= () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const httpBody = {
      data: {
          name: this.state.name,
          email: this.state.email,
          description: this.state.query,
      }

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.makeAnEnquiryId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.makeAnEnquiryApiCall
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  changeOpen = () => {
    this.setState({ open: true })
  }
  handleModelClose = () => {
    this.setState({ open: false })
  }
  handlemodalopen=()=>{
    this.setState({ open: true })
  }
  makeAnEnquiry = () => {
    let isFormValid = true;
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (this.state.name === "") {
      this.state.errors['name'] = configJSON.makeAnEnquiryNameError
      isFormValid = false
    }
    if (this.state.email === "") {
      this.state.errors['email'] = configJSON.makeAnEnquiryEmailError
      isFormValid = false
    }
    else if (!regex.test(this.state.email)) {
      this.state.errors['email'] = configJSON.makeAnEnquiryEmailErrorValid
      isFormValid = false
    }
    if (this.state.query === "") {
      this.state.errors['query'] = configJSON.makeAnEnquiryQueryError
      isFormValid = false
    }
    return isFormValid
  }
  handleOnChange=(event: React.ChangeEvent<HTMLInputElement>)=>{
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]:value
    }));
   }

  onVideoPause = () => {
    this.setState({ showVideoIcon: true });
    const video = document.getElementById("video-id") as HTMLVideoElement;
    if (video) {
      video.removeAttribute("controls");
    }
  }

  onVideoPlay = () => {
    this.setState({ showVideoIcon: false });
  }

  handleVideoApiResponse = (responseJson: { data: { attributes: { image: any, video_url: string } }[] }) => {
    this.setState({ knowladgeVideo: responseJson.data[0].attributes.video_url ,knowladgeImage: responseJson.data[0].attributes.image })
  }

  handleBlogApiResponse = (responseJson: { 
    data: {
    id: string;
    attributes: {
      title: string;
      description: string;
      video_url: string;
      link:string;
      image: string;
      created_at: string;
      author: string;
    };

  }[]
  } ) => {
    this.setState({ responseRecentArticles: [...responseJson.data] })
  }
  handleAllAriicleResponse= (responseJson: { 
    data: {
    id: string;
    attributes: {
      title: string;
      description: string;
      video_url: string;
      link:string;
      image: string;
      created_at: string;
      author: string;
    };

  }[]
  } ) => {
    this.setState({ responseRecentArticlesAll: [...responseJson.data],filteredData:[...responseJson.data] })
  }

  loadMore = () => {
    this.setState({ loadMore: this.state.loadMore + 6 })
  }
 handleFocus = (event: { target: { name: string; }; }) => {
  const { name } = event.target;
  this.setState((prevState) => ({
    errors: {
      ...prevState.errors,
      [name]: "",
    },
  }));
}
handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
  if (reason === configJSON.clickaway) {
    return;
  }

  this.setState({ snackbarOpen: false })
};
  makeAnEnquirySubmit = () => {
    if (this.makeAnEnquiry()) {
      this.makeAnQuery()
    }
    else {

      this.setState({
        errors: this.state.errors
      })
    }
  }

  navigateToDashBoard=()=>{
    localStorage.getItem("authToken")?this.props.navigation.history.push(configJSON.navToDashboard):this.props.navigation.history.push(configJSON.login)
  }
  handledashprofile=()=>{
    this.props.navigation.history.push(configJSON.UserProfileBasicBlock)
  }
  handleToLandingPageTo=()=>{
    this.props.navigation.history.push("")
  }
  handleOpenSearch=()=>{
this.setState({searchknowledge:true})
  }
  handleCloseSearch=()=>{
this.setState({searchknowledge:false,searchStatus:false,filteredData:this.state.responseRecentArticles})
  }

  handleDateTime=(inputDate:string)=>{
    const months = [
      "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
      "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
    ];
  
    const dateObj = new Date(inputDate);
    const month = months[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
  
    return `${month} ${day} ${year}`;
  }
  handleSearchClick  = async() => {
    let { searchInput } = this.state;
    let filteredData = this.state.responseRecentArticlesAll.filter((item) => {

      let title = item.attributes.title.toLowerCase();
      let description = item.attributes.description.toLowerCase();
      return title.includes(searchInput.toLowerCase()) || description.includes(searchInput.toLowerCase());
    });

    
     this.setState({ filteredData });
   
    this.setState({searchStatus:true})

   

  };
  handleInputChange = (event:  React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ searchInput: value });
  };
  handlenewTab=(item:string)=>{
    if(item != "")window.open(item, '_blank')
    else return false
   
  }

  handletaxcalculator = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

      const getpasswordfrom = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );

      this.TaxcalculatorAnalytics  = getpasswordfrom.messageId

      getpasswordfrom.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.TaxcalculatorAnalyticsEndPoint
      );

      getpasswordfrom.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
      );

      getpasswordfrom.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType

      );
      runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);
  }

  handleknowledgeanalytics = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

      const getpasswordfrom = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );

      this.KnowledgeAnalyticsEndPoint  = getpasswordfrom.messageId

      getpasswordfrom.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.KnowledgeAnalyticsEndPoint
      );

      getpasswordfrom.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
      );

      getpasswordfrom.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType

      );
      runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);
  }
 
  handleSearchStatus=()=>{
    return this.state.searchStatus && this.state.filteredData && this.state.filteredData.length > 0
  }

  downloadJSON = async (tokenId:string) => {
  const token = await getStorageData(tokenId);
  if(token){

    const downloadURL = `${baseURL}/${configJSON.DownloadJSON}token=${token}`;
    
    const newWindow = window.open(downloadURL, '_blank');
  }
  else{
    this.props.navigation.history.push("/login")
  }
  };
  capitalDialogClose=()=>{
    this.setState({
      capitalD:false
    })
  }
  capitalDialogOpen=()=>{
    this.setState({
      capitalD:true,
      capitalCalculate:""
    })
  }

  handleRadioChange = (value:string,name:keyof S) => {
    this.setState({
      ...this.state,
      [name]:value as unknown as Pick<S, keyof S>
    })
  }

  navigateToCapitalGain = () => {
    this.props.navigation.navigate("CapitalGainCalc",{type:this.state.capitalCalculate})
  }
  // Customizable Area End
}
