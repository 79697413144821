import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";

interface ErrorObject {
  [key: string]: boolean;
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  dropdown: {},
  type: string,
  onClick: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  DonationsCharitableOrganizations: {
    NameoftheDonee: string;
    DonationAmountCash: string;
    DonationAmountNonCash: string;
    PANofDonee: string;
    AddressLine: string;
    PINCode: string;
    TownCity: string;
    StateDistrict: string;
    CommonOrganizations:string;
  };
  errors: ErrorObject;
  status: boolean;
  PANError :string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}

export default class DonationsCharitableOrganizationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  donationapicallID: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {
      DonationsCharitableOrganizations: {
        NameoftheDonee: "",
        DonationAmountCash: "",
        DonationAmountNonCash: "",
        PANofDonee: "",
        AddressLine: "",
        PINCode: "",
        TownCity: "",
        StateDistrict: "choose",
        CommonOrganizations:"choose"
      },
      errors: {},
      status: false,
      PANError :""
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const successMessage = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (this.donationapicallID === responseData) {
      this.setState({ status: true });
      this.handlesaveDonations(successMessage);
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  handlesaveDonations = (apiResponse: {}) => {
    if (apiResponse) {
      setTimeout(() => {
        this.setState({ status: false });
      }, 1000);
      this.setState({
        DonationsCharitableOrganizations: {
          NameoftheDonee: "",
          DonationAmountCash: "",
          DonationAmountNonCash: "",
          PANofDonee: "",
          AddressLine: "",
          PINCode: "",
          TownCity: "",
          StateDistrict: "",
          CommonOrganizations:""
        },
      },()=>{this.props.onClick()})
    }
  }
  handleonChangeTextData = (event: { target: { name: string; value: string; }; }) => {
    const { name, value } = event.target;
    if (!/^[a-zA-Z ]*$/.test(value) || value.length > 30) {
      return;
    }
    let val = value.replace(/^\s+/, "");
    val = val.replace(/\s{2,}/g, ' ');
    this.setState((prevstate) => ({
      DonationsCharitableOrganizations: {
        ...prevstate.DonationsCharitableOrganizations,
        [name]: val,
      },
    }))

  }
  handleonChangeTextNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if(/[^0-9\b]/.test(value) || value.length > 12) {
      return
    }
    this.setState((prevstate) => ({
      DonationsCharitableOrganizations: {
        ...prevstate.DonationsCharitableOrganizations,
        [name]: value,
      },
    }))

  }

  
  handleOnChangePan = (event: { target: { name: string; value: string; }; }) => {
    const { name, value } = event.target;
    let panerror = "";
    const panPattern = /^[A-Z]{5}\d{4}[A-Z]$/i;
    if (value.length > 10) {
        return;  
    }
    if (value.length === 0) {
        panerror = "";
    } 
    else if (!panPattern.test(value)) {
        panerror = "Invalid PAN";
    }
    this.setState((prevstate) => ({
        DonationsCharitableOrganizations: {
            ...prevstate.DonationsCharitableOrganizations,
            [name]: value,
        },
        PANError: panerror
    }));
}


  handleonChangeAddress = (event: { target: { name: string; value: string; }; }) => {
    const { name, value } = event.target;
    let val = value.replace(/^\s+/, "");
    val = val.replace(/\s{2,}/g, ' ');
    if (!/^[a-zA-Z0-9 ]*$/.test(val) || val.length > 50) {
        return;
    }
    this.setState((prevstate) => ({
      DonationsCharitableOrganizations: {
        ...prevstate.DonationsCharitableOrganizations,
        [name]: val,
      },
    }))

  }
  handleonChangePin = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if(/[^0-9\b]/.test(value) || value.length > 6 || (value.length === 1 && value === '0')) {
      return
  }
    this.setState((prevstate) => ({
      DonationsCharitableOrganizations: {
        ...prevstate.DonationsCharitableOrganizations,
        [name]: value,
      },
    }))

  }
  handleonChangeDropdown = (event:any) => {
    const { name, value } = event.target;
    this.setState((prevstate) => ({
      DonationsCharitableOrganizations: {
        ...prevstate.DonationsCharitableOrganizations,
        [name]: value,
      },
    }))
  }
  handleBlurMultipage = (event: { target: { name: string, value: string } }) => {
    const { name, value } = event.target;
    this.setState((prevData) => ({
        ...prevData,
        errors: {
            ...prevData.errors,
            [name]: String(value).trim() === ""
        }
    }));
}
  validateFields = () => {
    const {
      DonationAmountCash,
      AddressLine,
      PINCode,
      TownCity,
      StateDistrict,
      PANofDonee
    } = this.state.DonationsCharitableOrganizations;
    const DonationObj = {DonationAmountCash, AddressLine, PINCode, TownCity, StateDistrict , PANofDonee }
    const emptyFields: Record<string, boolean> = {};
    const panPattern = /^[A-Z]{5}\d{4}[A-Z]$/i;
    for (const [fieldName, fieldValue] of Object.entries(DonationObj)) {
      if (fieldName == "PANofDonee") {
        if (fieldValue.length === 0) {
          emptyFields[fieldName] = false; 
        } else if (!panPattern.test(fieldValue)) {
          emptyFields[fieldName] = true;
        } else {
          emptyFields[fieldName] = false;
        }
      }
      else{
        if (String(fieldValue).trim() === "" || String(fieldValue).trim() === "choose") {
          emptyFields[fieldName] = true;
        }  else if (fieldName === "PINCode" && fieldValue.length < 6) {
          emptyFields[fieldName] = true;
        }
         else {
          emptyFields[fieldName] = false;
        }
      }
     
    }
    this.setState({ errors: emptyFields })
    return emptyFields
}



  handleOnFocus = (fieldName: string) => {
    this.setState((prevState:{errors:{}}) => ({
      errors: {
        ...prevState.errors,
        [fieldName]: "",
      },
    }));
  };
  deductionsPost = async () => {
    const {
      NameoftheDonee,
      DonationAmountCash,
      DonationAmountNonCash,
      PANofDonee,
      AddressLine,
      PINCode,
      TownCity,
      StateDistrict,
    } = this.state.DonationsCharitableOrganizations;
    const { type } = this.props;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData(configJSON.authToken)
    };

    const httpBody = {
      donation: {
        
        name_of_donee: NameoftheDonee,
        donation_amount_cash: DonationAmountCash,
        donation_amount_non_cash: DonationAmountNonCash,
        pan_of_donee: PANofDonee,
        address_line: AddressLine,
        pincode: PINCode,
        city: TownCity,
        state: StateDistrict,
        donation_type: this.props.type,
        deduction_id:this.props.id 
      }
    };
    const getpasswordfrom = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.donationapicallID = getpasswordfrom.messageId;

    getpasswordfrom.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.donationEndPoint
    );

    getpasswordfrom.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getpasswordfrom.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getpasswordfrom.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod

    );
    runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);
  }
  saveDonations = () => {
    const checkvalidation = this.validateFields();

    if (Object.values(checkvalidation).every(value => value !== true)) {

      this.deductionsPost()
    }

  }

  handleMessage = ( reason?: string) => {
    if (reason === configJSON.clickaway) {
      return;
    }

    this.setState({ status: false })
  };
  // Customizable Area End
}
