import React from "react";

import {
  Box,
  // Customizable Area Start
  styled,
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Header from "../../../components/src/Header.web";
import { LeftArrow, calenderIcon, info } from "./assets";
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers';
import { withStyles } from "@material-ui/core/styles";

// Customizable Area End

import CapGainsController, {
    Props,
    configJSON
} from "./CapGainsController.web";
import Toster from "../../../components/src/Toster";
import DashboardPanselector from "../../dashboard/src/DashboardPanselector.web";
import RemoveSelectedDate from "../../../components/src/RemoveSelectedDate.web";

export class CapitalGainBond extends CapGainsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
    renderStandardTextfield = (name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, 
        error: boolean | undefined,EditableFlag:boolean) => {
        const { classes } = this.props;
        return (
            <Box>
            <TextField
            className={classes.selectdropDown}
            placeholder={configJSON.bondsPlaceholder}
            variant="outlined"
                name={name}
                onChange={onChange}
                type={type}
                inputProps={{
                    readOnly:EditableFlag,
                    disabled: EditableFlag,
                }}
                onFocus={this.handleFocusBonds}
                value={value}
                FormHelperTextProps={{
                    style: { margin: '-8px 0 0 0' },
                }}
                data-test-id={`common_Input_${name}`}
                onBlur={this.handleBlurBonds}

            />
            <Typography style={{fontSize:"12px",color:"red",marginTop:"-7px"}}>{this.getErrorMessageByName(name)}</Typography>
        </Box>
        )
    }
    renderCostumTextfiled = (name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined) => {
        const { classes } = this.props;

        return (
            <Box>
            <Box className={classes.amountBond}>
                <Box className={classes.ruppeslogo}>
                    <Typography className={classes.rupeessstylesBonds}>₹</Typography>
                </Box>
                <TextField
                    variant="outlined"
                    className={classes.textfiledBond}
                    name={name}
                    type="string"
                    value={value}
                    placeholder={configJSON.passplaceHolder}
                    FormHelperTextProps={{
                        style: { margin: '0' },
                    }}
                    onFocus={this.handleFocusBonds}
                    onChange={onChange}
                    data-test-id={`common_Input_${name}`}
                    onBlur={this.handleBlurBonds}
                />
            </Box>
                 <Typography style={{fontSize:"12px",color:"red"}}>{this.getErrorMessageByName(name)}</Typography>
                 </Box>
        )
    }
   
  
    renderPurchaseDate = () => {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Box className={classes.textAlignment}>
                    <Box className={classes.boxwidth}>
                        <Typography className={classes.labelheading}>Date of Purchase</Typography>
                </Box>
                <Box>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Box className={classes.amountBond}>
                        <Box className={classes.ruppeslogo}>
                            <img src={calenderIcon}
                                onClick={() => this.setState({ isForcePickerOpen: true })}
                            data-test-id="calender"
                                className={classes.rupeessstylesBonds} />
                        </Box>
                        <DatePicker
                            inputVariant="outlined"
                            disableToolbar
                            autoOk
                            variant="inline"
                            placeholder="11/22/3333"
                            className={classes.textfiledBond}
                            onChange={this.handleDateChange}
                            open={this.state.isForcePickerOpen}
                            format="DD/MM/YYYY"
                            onOpen={() => this.setState({ isForcePickerOpen: true })}
                            onClose={() => this.setState({ isForcePickerOpen: !this.state.isForcePickerOpen })}
                            data-test-id="calenderClose"
                            value={this.state.dateOfPurchase}
                            maxDate={Date.now()}
                        />
                        {this.state.dateOfPurchase && <RemoveSelectedDate data-test-id='removeDateOfPurchase' dateState={'dateOfPurchase'} onClearDate={this.emptySelectedDate} />}
                    </Box>
                </MuiPickersUtilsProvider> 
                <Typography style={{fontSize:"12px",color:"red"}}>{this.getErrorMessageByName("dateOfPurchase")}</Typography>
                 
                </Box>
                </Box>
               
            </React.Fragment>
        )
    }

    renderSalesDateForBonds = () => {
        const { classes } = this.props;
        return (
            <React.Fragment>
                 <Box className={classes.textAlignment}>
                    <Box className={classes.boxwidth}>
                        <Typography className={classes.labelheading}>Date of Sale</Typography>
                </Box>
                <Box>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Box className={classes.amountBond}>
                        <Box className={classes.ruppeslogo}>
                            <img src={calenderIcon}
                             data-test-id="calender1"
                             onClick={() => this.setState({ SalesDatePicker: true })}
                                className={classes.rupeessstylesBonds} />
                        </Box>
                        <DatePicker
                            variant="inline"
                            inputVariant="outlined"
                            disableToolbar
                            className={classes.textfiledBond}
                            autoOk
                            data-test-id="calenderClose1"
                            placeholder="11/22/3333"
                            open={this.state.SalesDatePicker}
                            onOpen={() => this.setState({ SalesDatePicker: true })}
                            onClose={() => this.setState({ SalesDatePicker: !this.state.SalesDatePicker })}
                            value={this.state.dateOfSale}
                            onChange={this.handleDateChangeSales}
                            format="DD/MM/YYYY"
                            maxDate={Date.now()}
                        />
                        {this.state.dateOfSale && <RemoveSelectedDate dateState={'dateOfSale'} onClearDate={this.emptySelectedDate} />}
                    </Box>
                </MuiPickersUtilsProvider>
                <Typography style={{fontSize:"12px",color:"red"}}>{this.getErrorMessageByName("dateOfSale")}</Typography>

                </Box>
                </Box>
            </React.Fragment>
        )
    }

    renderCommonFieldfor = (commonAttributes:
        {
            amountActive: boolean,
            heading: string,
            EditableFlag:boolean,
            amountValues: { name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined },
            textInputValues: { name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined }
        }) => {
        const { classes } = this.props;
        const { amountValues, textInputValues } = commonAttributes
        let rendertxt;
        if (commonAttributes.amountActive) {
            rendertxt = this.renderCostumTextfiled(amountValues.name, amountValues.value, amountValues.type, amountValues.onChange, amountValues.error)
        } else {
            rendertxt = this.renderStandardTextfield(textInputValues.name, textInputValues.value, textInputValues.type, textInputValues.onChange,
                textInputValues.error,commonAttributes.EditableFlag)
        }

        return (
            <Box>
                <Box className={classes.textAlignment}>
                    <Box className={classes.boxwidth}>
                        <Typography className={classes.labelheading}>{commonAttributes.heading}</Typography>
                    </Box>
                    <Box>
                        {rendertxt}
                    </Box>
                </Box>
            </Box>

        )
    }
    rendercommonBonds = () => {
        const { classes } = this.props;
        const { error, periodOfHolding, units, salesConsideration,
             indexedCost, salesAmount, purchaseAmount } = this.state;
        return (
            <>
                        <Box className={classes.textAlignment}>
                            <Box className={classes.boxwidth}>
                                <Typography className={classes.labelheading}>Type of Security</Typography>
                            </Box>
                            <Box>
                            <Box className={classes.selectdropDown}>
                        <FormControl fullWidth variant="outlined">
                        {this.state.security == "" &&
                            <InputLabel htmlFor="typeOfSecurity" style={{ color: '#ABABB0', fontSize: '16px' }} shrink={Boolean(this.state.security)}>
                                Shares/equity mutual funds
                            </InputLabel>}
                            <Select
                                variant="outlined"
                                fullWidth
                                name="typeOfSecurity"
                                data-test-id="selectTypeOfSecurity_testid"
                                value={this.state.security}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                }}
                                onChange={this.onChangeTypeOfSecurity}
                            >

                                {this.state.typeOfSecurity.map((item: any) => (
                                    <MenuItem key={item.key} value={item.value as string}>
                                        {item.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                            </Box>
                        </Box>
                         {this.state.securityFlag && (
                            <Box style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                padding: '2em 8em 0.5em 2em',
                                gap: "10px",
                                cursor:"pointer"
                            }}
                            onClick={this.navigateToConsolidedEntry}
                            data-test-id="add"
                            >

                                <div
                                    className={classes.AddSecurityButton}
                                >
                                    <Typography className={classes.plusText}>+</Typography>

                                </div>
                                <Box className={classes.boxwidth}>
                                    <Typography className={classes.AddConsilidedText}>Add consolidated entry</Typography>
                                </Box>
                            </Box>
                        )}
                     {this.state.ShowDetailForm && 
                     <>
                        {this.renderCommonFieldfor({
                            heading: configJSON.periodOfHolding,
                            amountActive: false,
                            EditableFlag: false,
                            amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                            textInputValues: { name: "periodOfHolding", value: periodOfHolding, type: "text", onChange: this.handleOnchangeNumberBonds, error: error["Field cant be blank"] },
                        })}
                        {this.renderPurchaseDate()}
                        {this.renderSalesDateForBonds()}
                        {this.renderCommonFieldfor({
                            heading: configJSON.units,
                            amountActive: false,
                            EditableFlag:false,
                            amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                            textInputValues: { name: "units", value: units, type: "text", onChange: this.handleOnchangeNumberBonds, error: error["commonBusiness"] },
                        })}
                        {this.renderCommonFieldfor({
                            heading: configJSON.salesConsideration,
                            amountActive: false,
                            EditableFlag:false,
                            amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                            textInputValues: { name: "salesConsideration", value: salesConsideration, type: "text", onChange: this.handleOnchangeBonds, error: error["commonBusiness"] },
                        })}
                        {this.renderCommonFieldfor({
                            heading: configJSON.salesAmount,
                            amountActive: true,
                            EditableFlag:false,
                            amountValues: { name: "salesAmount", value: salesAmount, type: "text", onChange: this.handleOnchangeNumberBonds, error: error["netProfit"] },
                            textInputValues: { name: "salesAmount", value: salesAmount, type: "", onChange: undefined, error: false },
                        })}
                        {this.renderCommonFieldfor({
                            heading: configJSON.purchaseAmount,
                            amountActive: true,
                            EditableFlag:false,
                            amountValues: { name: "purchaseAmount", value: purchaseAmount, type: "text", onChange: this.handleOnchangeNumberBonds, error: false },
                            textInputValues: { name: "purchaseAmount", value: purchaseAmount, type: "text", onChange: this.handleOnchangeNumberBonds, error: error["commonBusiness"] },
                        })}
                        {this.renderCommonFieldfor({
                            heading: configJSON.indexedCost,
                            amountActive: false,
                            EditableFlag:false,
                            amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                            textInputValues: { name: "indexedCost", value: indexedCost, type: "text", onChange: this.handleOnchangeNumberBonds, error: error["commonBusiness"] },
                        })}
                    </>}
            </>
        )
    }
  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <MainWrapper maxWidth={false}>
         <Box className="body" id="mainbody">
         <DashboardPanselector navigation={this.props.navigation} id={""} data-test-id="pancard" />
            <Box className="capital_form" id="form">
                <Box className="ls_main-title">
                    <img src={LeftArrow} onClick={() => this.props.changeTab("Capital Gains")}/>
                    <Typography data-test-id="back_arrow" className="ls_body_title-text">Capital Gains</Typography>
                </Box>
                <Box data-test-id="mainbody" className={classes.backgroundWrapper}>
                        {this.rendercommonBonds()}
                </Box>
                {this.state.ShowDetailForm && (<>
                        <Box >
                            <Button

                                className={classes.buttonComponentBonds} onClick={this.handleSaveData} data-test-id="save" >Save</Button>
                        </Box>
                    </>)}
            </Box>
            <Toster {...this.state.toast} handleTostClose={this.handleTostClose} />
          </Box>
      </MainWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainWrapper = styled(Container)({
    width: "100%",
    "& .capital_box-primary-1": {
        padding: "0px 10px",
        borderRadius: "10px",
        justifyContent: "space-between",
        display: "flex",
        backgroundColor: "#F4F6F5",
      alignItems: "center",
      width: "400px",
      marginRight: "20px",
      height: "56px",
    },
    "& .ls_main-title": {
        display: "flex",
        marginBottom: "30px",
        "&>img": {
            marginRight: "30px",
            cursor: "pointer"
        }
    },
    "& .ls_body_title-text": {
        color: "#36403B",
        fontFamily: "Nunito",
        fontSize: "27px",
        fontStyle: "normal",
        fontWeight: 700,
    },
   
    "& .capital_regime": {
      width: "100%",
      alignItems: "center",
      display: "flex",
      justifyContent: "right",
    },
    "& .capital_box-primary-2": {
      backgroundColor: "#F4F6F5",
      width: "350px",
      borderRadius: "10px",
      height: "56px",
      display: "flex",
      alignItems: "center",
      padding: "20px",
    },
    "& .capital_header": {
        margin: "20px 0px",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      },
    "& .capital_form": {
    padding: "30px",
    backgroundColor: "#F4F6F5",
      borderRadius: "16px"
    },   
  });

export const styles = (theme: any) => ({
    ruppeslogo: {
        background: "#ddfef7",
        padding: '1em 2em 1em 1em',
        height: '42px',
        borderRadius: "8px 0px 0px 8px",
        display: "flex",
        alignItems: "center"
    },
    
    textAlignment: {
        justifyContent: "flex-start",
        display: "flex",
        alignItems: "center",
        padding: '2em 8em 0.5em 2em',
        [theme.breakpoints.between('xs', 'sm')]: {
            display: "unset",
        },
        gap: "50px",
    },
    amountBond: {
        display: "flex"
    },
    overrides: {
      
        MuiPickersDay: {
            day: {
                color: 'blue',
            },
            dayDisabled: {
                color: 'grey',
            },
            daySelected: {
                backgroundColor: 'blue',
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: 'lightblue',
            },
        },
    },
    AddSecurityButton: {
        borderRadius: '50%',
        background: "#337152",
        color: '#fff',
        width: '20px',
        border: 'none',
        height: '20px',
        cursor: 'pointer',
        fontSize: '14px',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    selectdropDown: {
        width: "500px",
        maxWidth: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
            height: '45px',
            top: '4px'
        },
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
    },
  backgroundWrapper:
     {
         marginTop: '2em',
         background: "#FFF",
         borderRadius: "16px 10px 10px 16px",
        padding: '2em 2em 2em 0em',
        "@media(max-width:484px)": {
            padding: '2em 0 2em 0em',
        },
    },
   
    labelheading: {
        [theme.breakpoints.between('xs', 'sm')]: {
            marginTop: '3em',
        },
        fontSize: "17px",
        color: "rgba(54,64,59,0.7)",
        fontWeight: 600,
        fontFamily: "Nunito",
    },
  
    textfiledBond: {

        "& input": {
            padding: "11px 0px 11px 12px",

            border: "1px solid #grey"
        },
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderLeft: 'none',
                borderRadius: "0px 8px 8px 0px",
            },
        },

    },
    rupeessstylesBonds: {
        color: "rgba(54,64,59,0.9)",
        fontWeight: 400,
        fontSize: "14px",
        fontFamily: "Nunito",
    },

    conditionalheadingBonds: {
        padding: "2em 0em 0 0em",
        display: "flex",
        alignItems: "center",
        gap: '4px',
        cursor: "pointer"
    },
    boxwidth: {
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '100%',
        },
        width: '30%',
    },
    buttonComponentText: {
        fontWeight: "bold",
        fontFamily: "Nunito",
        fontSize: '20px',
        color: "#000",
    },
    securityButton: {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        background: "#337152",
        color: '#fff',
        display: 'flex',
        border: 'none',
        cursor: 'pointer',
        fontSize: '14px',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none',
    },
    AddConsilidedText: {
        fontSize: "16px",
        fontWeight: 'bold',
        fontFamily: "Nunito",
    },
    buttonComponentBonds: {
        borderRadius: "5px",
        color: "#ffff",
        fontFamily: "Nunito",
        fontWeight: 600,
        background: "#337152",
        margin: "2.5em 1em 4em 0",
        '&:hover': {
            background: "#337152",
        },
        padding: '0.5em 4em 0.5em 4em',
    },
    plusText: {
        fontSize: "20px",
        fontWeight: 'bold',
        fontFamily: "Nunito"
    },
   

})

export default withStyles(styles as any)(CapitalGainBond);
// Customizable Area End
