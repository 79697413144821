import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
import { WithStyles } from "@material-ui/core";
export const configJSON = require("./config");
import { getStorageData } from "../../../framework/src/Utilities";

interface ErrorObject {
    [key: string]: boolean;
}

export interface ExpenseObject {
    id:number,
    description:string,
    amount:number | string
}
// Customizable Area End

export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    open: boolean;
    type: string;
    onClick: (type: string) => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    futureIntraday: boolean;
    nameOfBusiness: string;
    totalPositiveTrades: string;
    totalNegitiveTrades: string;
    directExpenses: string;
    indirectExpenses: string;
    turnover: string,
    grossProfit: string,
    expenditure: string,
    netProfit: string,
    error: ErrorObject;
    dExpenses: ExpenseObject[];
    idExpenses: ExpenseObject[];
    fnOId:number;
    snackbarOpen: boolean,
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class FutureOptionsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    futureAndOptionscallID: string = ""
    getFNOApiId: string = ""
    updateFNOApiId: string = ""
    deleteExpenseApiId: string = ""
    getIntradayApiId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            futureIntraday: false,
            nameOfBusiness: "",
            totalPositiveTrades: "",
            totalNegitiveTrades: "",
            directExpenses: "choose",
            indirectExpenses: "choose",
            turnover: "",
            grossProfit: "",
            expenditure: "",
            netProfit: "",
            error: {},
            dExpenses: [{id:0,description:"",amount:0}],
            idExpenses: [{id:0,description:"",amount:0}],
            fnOId:0,
            snackbarOpen: false,
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        const { type } = this.props
        if(type === configJSON.futuresProps ){
            this.getFNO()
        }
        else
        {
            this.getIntraday()
        }
        // Customizable Area End
    }
    // Customizable Area Start
    
    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            switch(apiRequestCallId){
                case this.getFNOApiId:
                    this.setFnoDetial(responseJson)
                    break;
                case this.updateFNOApiId:
                case this.futureAndOptionscallID:
                    this.setState({ snackbarOpen: true }) 
                    break;
                case this.getIntradayApiId:
                    this.setIntraDetial(responseJson)
            }
        }

        // Customizable Area End
    }

    setFnoDetial = (responseJson:any) => {
        if (responseJson && responseJson.data && responseJson.data.length > 0) {
            const fnodata = responseJson.data[0].attributes;
            this.setState({
                fnOId: fnodata.id,
                nameOfBusiness: fnodata.name_of_business,
                totalPositiveTrades: fnodata.positive_trade,
                totalNegitiveTrades: fnodata.negative_trade,
                idExpenses: fnodata.indirect_expenses.map((idExp:ExpenseObject)=>idExp),
                dExpenses: fnodata.direct_expenses.map((dExp:ExpenseObject)=>dExp),
            })
        }
    }
    setIntraDetial = (responseJson:any) => {
        if (responseJson && responseJson.data) {
            const intradata = responseJson.data.attributes;
            this.setState({
                fnOId: intradata.id,
                nameOfBusiness: intradata.name_of_business,
                turnover: intradata.speculative_activity,
                grossProfit: intradata.gross_profit,
                netProfit: intradata.net_profit,
                expenditure: intradata.expenditure,
            })
        }
    }
    handleOnchangeFutures = (event: { target: { name: string, value: string } }) => {
        const { name, value } = event.target;
        if (event.target.value !== "" && !/^\d+(\.\d+)?$/.test(value)) {
            
            return;
        }
        this.setState((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }
    handleOnchangeName = (event: { target: { name: string, value: string } }) => {
        const { name, value } = event.target;
        this.setState((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }
    handleFocusFutures = (event: { target: { name: string, value: string } }) => {
        const { name } = event.target;
        this.setState((prevData) => ({
            ...prevData,
            error: {
                ...prevData.error,
                [name]: false
            }
        }));
    }
  

   
 
    handleSubmitFutures = () => {
        const { type } = this.props;
        let validate = false
        if (!validate) {
            // post api fo futures and options 
            this.deductionsPost()
        } else {
            this.setState({
                error: this.state.error
            })
        }
    }


    ///post api call for futures and options start
    deductionsPost =  async() => {
        const { type } = this.props
        let validate = type === configJSON.futuresProps ? configJSON.futuresAndOptionsApi : configJSON.intradayApi
        if(this.state.fnOId>0) {
            this.updateFNO()
            return
        }
        const {
            nameOfBusiness,
            totalPositiveTrades,
            totalNegitiveTrades,
            turnover,
            grossProfit,
            expenditure,
            netProfit,
            dExpenses,
            idExpenses
         } = this.state;
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const httpBody = {
            data: {
                
                    name_of_business: nameOfBusiness,
                    ...(type === configJSON.futuresProps
                        ? {
                            positive_trade: totalPositiveTrades,
                            negative_trade: totalNegitiveTrades,
                            direct_expenses: dExpenses.map((dexp)=>{return ({amount:dexp.amount,description:dexp.description})}),
                            indirect_expenses: idExpenses.map((dexp)=>{return ({amount:dexp.amount,description:dexp.description})}),
                            direct_expanse: dExpenses.reduce((accumulator,current)=>accumulator+Number(current.amount),0),
                            indirect_expanse: idExpenses.reduce((accumulator,current)=>accumulator+Number(current.amount),0)
                        }
                        : {
                            speculative_activity: turnover,
                            gross_profit: grossProfit,
                            net_profit: netProfit,
                            expenditure: expenditure
                        })
                
            }
        };
        const getpasswordfrom = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.futureAndOptionscallID = getpasswordfrom.messageId;

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            validate
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod

        );
        runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);
    }
    ///post api call for futures and options start     

      getFNO= async ()=>{
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token":  await getStorageData(configJSON.authToken)
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFNOApiId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getFNOApiEndPoint}`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
       requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
      updateFNO = async () => {
        const { type } = this.props;
          const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token":  await getStorageData(configJSON.authToken)
          };
    
          const {
            nameOfBusiness,
            totalPositiveTrades,
            totalNegitiveTrades,
            turnover,
            grossProfit,
            expenditure,
            netProfit,
            dExpenses,
            idExpenses
         } = this.state;

        const body = {
            data: {
                name_of_business: nameOfBusiness,
                ...(type === configJSON.futuresProps
                        ? {
                            positive_trade: totalPositiveTrades,
                            negative_trade: totalNegitiveTrades,
                            direct_expenses: [...dExpenses.filter((dexp)=>dexp.id!=0),...dExpenses.filter((dexp)=>dexp.id==0).map((dexp)=>{return ({amount:dexp.amount,description:dexp.description})})],
                            indirect_expenses: [...idExpenses.filter((idExp)=>idExp.id!=0),...idExpenses.filter((idexp)=>idexp.id==0).map((idexp)=>{return ({amount:idexp.amount,description:idexp.description})})],
                            direct_expanse: dExpenses.reduce((accumulator,current)=>accumulator+Number(current.amount),0),
                            indirect_expanse: idExpenses.reduce((accumulator,current)=>accumulator+Number(current.amount),0)
                        }
                        : {
                            speculative_activity: turnover,
                            gross_profit: grossProfit,
                            net_profit: netProfit,
                            expenditure: expenditure
                    })
            }
        };
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.updateFNOApiId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            type=== configJSON.futuresProps?`${configJSON.updateFNOApiEndPoint}${this.state.fnOId}`:`${configJSON.updateIntraApiEndPoint}${this.state.fnOId}`
          );
    
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
    
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
          );
    
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.examplePatchAPiMethod
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
        
      };
     
      deleteFNO = async (expId:number) => {
        
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token":  await getStorageData(configJSON.authToken)
        };
        const endpoint = `${configJSON.deleteFNOApiEndPoint}${expId}?future_option_id=${this.state.fnOId}`
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteExpenseApiId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endpoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
       requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.deleteMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
      onChangeExpenseDescription = (name:keyof S,index:number,event:React.ChangeEvent<HTMLInputElement>) => {
        let desc = this.state[name] as ExpenseObject[];
        desc[index].description = event.target.value;
        this.setState((prevData) => ({
            ...prevData,
            [name]: desc
        }))
      }

      onChangeExpenseAmount = (name:keyof S,index:number,event:React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value !== "" && !/^\d+(\.\d+)?$/.test(event.target.value)) {
            return;
        }
        let desc = this.state[name] as ExpenseObject[];
        desc[index].amount = Number(event.target.value);
        desc[index].amount = event.target.value === "" ? "" : Number(event.target.value);
        this.setState((prevData) => ({
            ...prevData,
            [name]: desc
        }))
      }

      addExpBox = (name:keyof S) => {
        let desc = this.state[name] as ExpenseObject[];
        desc.push({id:0,description:"",amount:0})
        this.setState((prevData) => ({
            ...prevData,
            [name]: desc
        }))
      }

      removeExpBox = (name:keyof S,index:number) => {
        let desc = this.state[name] as ExpenseObject[];
        if(desc[index].id>0){
            this.deleteFNO(desc[index].id)
        }
        else{
            desc.splice(index,1)
            this.setState((prevData) => ({
                ...prevData,
                [name]: desc
            }))
        }        
      }
      getIntraday= async ()=>{
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token":  await getStorageData(configJSON.authToken)
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getIntradayApiId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getIntradayApiEndPoint}`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
       requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false })
        this.props.onClick("")
      };
    // Customizable Area End
}
