import React from "react";
import {
    // Customizable Area Start
    Container,
    Box,
    Button,
    Typography,
    Theme,
    TextField,
    FormControl,
    MenuItem,
    FormHelperText,
    Select,
    Grid,
    Snackbar,
    Slide
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Alert from '@material-ui/lab/Alert';
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FutureOptionsController, {
    Props,
    configJSON,
    ExpenseObject
} from "./FutureOptionsController.web";
import { deleteRed } from "./assets";
// Customizable Area End

export class FutureOptions extends FutureOptionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderStandardInputforFutures = (name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined) => {
        const { classes } = this.props;
        return (
            <TextField
                className={classes.selectdropDown}
                variant="outlined"
                error={error}
                placeholder={configJSON.emptyPlaceholder}
                name={name}
                type={type}
                value={value}
                onChange={onChange}
                onFocus={this.handleFocusFutures}
                data-test-id={name}
                helperText={error && configJSON.requriedfield}
                FormHelperTextProps={{
                    style: { margin: '-12px 0 0 0' },
                }}

            />
        )
    }
    renderCostumTextfiledforFutures = (name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined,isExpense?:boolean) => {
        const { classes } = this.props;
        return (
            <>
                <Box className={`${classes.amountWrapper} ${isExpense && classes.expensePadding}`}>
                    <Box className={classes.ruppeslogoWrapper}>
                        <Typography className={classes.rupeessstyles}>₹</Typography>
                    </Box>
                    <TextField
                        className={classes.textfiledWrapper}
                        error={error}
                        variant="outlined"
                        placeholder={configJSON.passplaceHolder}
                        name={name}
                        type={type}
                        value={value}
                        onChange={onChange}
                        onFocus={this.handleFocusFutures}
                        data-test-id={name}
                        helperText={error && configJSON.requriedfield}
                        FormHelperTextProps={{
                            style: { margin: '0' },
                        }}
                    />
                </Box>
                <Box className={classes.topmargin}>
                    {this.props.type !== configJSON.futuresProps && type == "true" &&

                        <Typography variant="caption" className={classes.underhovertext} >
                            <Box className={classes.iconAlign}>
                                <HelpOutlineIcon />{configJSON.helpermessageIntraday}
                            </Box>
                        </Typography>
                    }
                </Box>
            </>
        )
    }
    renderSelectInputFutures = (stylesprop: string, name: string, value: string, onChange: any, error: boolean | undefined, apiResponse: any) => {
        const { classes } = this.props;
        return (
            <Box className={stylesprop}>
                <FormControl fullWidth variant="outlined" error={error}>
                    <Select
                        fullWidth
                        variant="outlined"
                        placeholder="select"
                        value={value}
                        error={error}
                        name={name}
                        onChange={onChange}
                        onFocus={this.handleFocusFutures}
                        data-test-id={name}
                    >
                        <MenuItem className={classes.menustyle} value="choose"> <span className={classes.menuItemcolor} >{configJSON.SelectInitial}</span></MenuItem>

                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                    <FormHelperText className={classes.formhelpertext}>{error && configJSON.requriedfield}</FormHelperText>

                </FormControl>
            </Box>
        )
    }

    renderExpenseSection = (commonAttributes:{heading:string,expenses:ExpenseObject[],name:string}) => {
        const marginTopHeading: any = {
            [configJSON.GrossProfit]: "-4rem",

        };
        const customStyleHeading = {
            marginTop: marginTopHeading[commonAttributes?.heading] || "0px",
        };
      const { classes } = this.props;
        return (
            <Box>
                <Box className={classes.textAlignment}>
                    <Box style={customStyleHeading} className={classes.boxwidth}>
                        <Typography className={classes.labelheading}>{commonAttributes.heading}</Typography>
                    </Box>
                    <Box className={classes.boxWidth}>
                        {this.renderExpenseFields(commonAttributes.expenses,commonAttributes.name)}
                    </Box>
                </Box>
            </Box>
        )
    }
    renderExpenseFields = (expenses:ExpenseObject[],name:any) => {
      const { classes } = this.props;
      return (
        <div >
            <Grid spacing={2} container className={classes.addnlGrid}>
                <Grid sm={5} item>
                    <Typography className={classes.descField}>Description</Typography>
                    {expenses.map((exp:ExpenseObject,index:number)=>{
                        return (
                            this.renderStandardInputforFutures(name+"desc"+index,exp.description , "text", this.onChangeExpenseDescription.bind(this,name,index),false)
                        )
                    })}
                </Grid>
                <Grid sm={5} item>
                    <Typography className={classes.descField}>Amount</Typography>
                    {expenses.map((exp:ExpenseObject,index:number)=>{
                        let expAmount = (exp && exp.amount && exp.amount.toString() || "")
                        return (
                           <Box style={{display:"flex",alignItems:"center",gap:"10px"}}> {this.renderCostumTextfiledforFutures(name+"amount"+index, expAmount, "text",this.onChangeExpenseAmount.bind(this,name,index), false, true)}
                           <img src={deleteRed} data-test-id={`${name}delete${index}`} onClick={this.removeExpBox.bind(this,name,index)} className={classes.deleteImg} />
                           </Box>
                        )
                    })}
                </Grid>
            </Grid>
        </div>
      )
    }

    renderCommonTextFieldforFutures = (commonAttributes:
        {
            heading: string,
            amountActive: boolean,
            selectActive: boolean,
            amountValues: { name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined },
            textInputValues: { name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined }
        }) => {
        const { classes } = this.props;
        const { amountValues, textInputValues } = commonAttributes
        let rendertxt;
        if (commonAttributes.amountActive) {
            rendertxt = this.renderCostumTextfiledforFutures(amountValues.name, amountValues.value, amountValues.type, amountValues.onChange, amountValues.error)
        }
        else if (commonAttributes.selectActive) {
            rendertxt = this.renderSelectInputFutures(classes.selectdropDown, amountValues.name, amountValues.value, amountValues.onChange, amountValues.error, "")
        }
        else {
            rendertxt = this.renderStandardInputforFutures(textInputValues.name, textInputValues.value, textInputValues.type, textInputValues.onChange, textInputValues.error)
        }
        const marginTopHeading: any = {
            [configJSON.GrossProfit]: "-4rem",

        };
        const customStyleHeading = {
            marginTop: marginTopHeading[commonAttributes?.heading] || "0px",
        };
        return (
            <Box>
                <Box className={classes.textAlignment}>
                    <Box style={customStyleHeading} className={classes.boxwidth}>
                        <Typography className={classes.labelheading}>{commonAttributes.heading}</Typography>
                    </Box>
                    <Box className={classes.boxWidth}>
                        {rendertxt}
                    </Box>
                </Box>
            </Box>

        )
    }
    rendercommonComponetFutures = () => {
        const { classes, type } = this.props;
        const { nameOfBusiness,
            totalPositiveTrades,
            totalNegitiveTrades,
            directExpenses,
            indirectExpenses,
            turnover,
            grossProfit,
            expenditure,
            netProfit,
            error } = this.state;
        let typeDefined = configJSON.futuresProps
        let turnoverPft = type === typeDefined ? "totalPositiveTrades" : "turnover"
        let grossPft = type === typeDefined ? "totalNegitiveTrades" : "grossProfit"
        
        let turnoverPftValue = type === typeDefined ? totalPositiveTrades : turnover
        let grossPftValue = type === typeDefined ? totalNegitiveTrades : grossProfit
        return (
            <>
                <Box className={classes.headingwrapeer}>
                    <Typography className={classes.subheadingdonations}>{configJSON.natureofBussiness}</Typography>
                </Box>
                {this.renderCommonTextFieldforFutures({
                    heading: configJSON.nameofBusiness,
                    amountActive: false,
                    selectActive: false,
                    amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                    textInputValues: { name: "nameOfBusiness", value: nameOfBusiness, type: "string", onChange: this.handleOnchangeName, error: error["nameOfBusiness"] },
                })}
                <Box className={classes.headingwrapeer}>
                    <Typography className={classes.subheadingdonations}>{configJSON.Receipts}</Typography>
                </Box>
                {this.renderCommonTextFieldforFutures({
                    heading: type === typeDefined ? configJSON.totalPos : configJSON.turnover,
                    amountActive: true,
                    selectActive: false,
                    amountValues: { name: turnoverPft, value: turnoverPftValue, type: "text", onChange: this.handleOnchangeFutures, error: error[turnoverPft] },
                    textInputValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                })}
                {this.renderCommonTextFieldforFutures({
                    heading: type === typeDefined ? configJSON.totalnegv : configJSON.GrossProfit,
                    amountActive: true,
                    selectActive: false,
                    amountValues: { name: grossPft, value: grossPftValue, type: "text", onChange: this.handleOnchangeFutures, error: error[grossPft] },
                    textInputValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                })}
                {type !== typeDefined ||
                    <>
                      
                        {this.renderExpenseSection({
                            heading: configJSON.directExpense,
                            expenses: this.state.dExpenses,
                            name: "dExpenses"
                        })}
                        <Box className={classes.iconArrangmment} data-test-id="addDirectExpense" onClick={this.addExpBox.bind(this,"dExpenses")}>
                            <AddCircleIcon />
                            <Typography className={classes.addMore1}>{configJSON.AddMoreDirectExpenses}</Typography>
                        </Box>

                       
                        {this.renderExpenseSection({
                            heading: configJSON.indirectExpense,
                            expenses: this.state.idExpenses,
                            name: "idExpenses"
                        })}
                        <Box className={classes.iconArrangmment} data-test-id="addInDirectExpense" onClick={this.addExpBox.bind(this,"idExpenses")}>
                            <AddCircleIcon />
                            <Typography className={classes.addMore1}>{configJSON.AddMoreIndirectExpenses}</Typography>
                        </Box>
                    </>
                }
                {type === typeDefined ||
                    <>
                        {this.renderCommonTextFieldforFutures({
                            heading: configJSON.Expenditure,
                            amountActive: false,
                            selectActive: false,
                            amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                            textInputValues: { name: "expenditure", value: expenditure, type: "string", onChange: this.handleOnchangeFutures, error: error["expenditure"] },
                        })}
                        {this.renderCommonTextFieldforFutures({
                            heading: configJSON.netProfits,
                            amountActive: false,
                            selectActive: false,
                            amountValues: { name: "", value: "", type: "", onChange: undefined, error: false },
                            textInputValues: { name: "netProfit", value: netProfit, type: "string", onChange: this.handleOnchangeFutures, error: error["netProfit"] },
                        })}
                    </>
                }
                {
                    type !== typeDefined ||
                    <Box className={classes.helperTextfutures}>
                        <Typography>
                            {configJSON.futuresHelperText}
                            <b className={classes.clickherelink}>Click here</b>
                            to file the Detailed P&L
                        </Typography>                 
                           </Box>
                }


            </>
        )
    }

    render() {
        const { classes, type, onClick } = this.props;
        return (
            <>
                <Container className={classes.mainWrapper} maxWidth={false}>
                    <Box className={classes.conditionalheading}>
                        <ArrowBackIosIcon fontSize="large" onClick={() => onClick("")} /><Typography className={classes.donationdeading}>{type === configJSON.futuresProps ? configJSON.Futures : configJSON.INTRADAY}</Typography>
                    </Box>
                    <Box className={classes.backgroundWrapper}>
                        {this.rendercommonComponetFutures()}
                    </Box>
                    <Box >
                        <Button className={classes.buttonComponent} data-test-id="next-submit" onClick={this.handleSubmitFutures}>{configJSON.NextFutures}</Button>
                    </Box>
                </Container>
            <Snackbar TransitionComponent={Slide} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }} 
              open={this.state.snackbarOpen}
              autoHideDuration={2000} 
              onClose={this.handleSnackbarClose}
              >
                <Alert severity="success">
                   Data saved succesfully
                </Alert>
              </Snackbar>
            </>
        )
    }
}
export const styles = (theme: Theme) => ({
    clickherelink:{
        cursor: 'pointer', 
        textDecoration: 'underline',
        margin:"0 5px 0 5px",
        // fontWeight:"bold" as any
    },
    boxWidth: {
        width: "60%"
    },
    formhelpertext: { margin: '-12px 0 0 0' },
    menuItemcolor: {
        color: "#ABABB0"
    },
    menustyle: {
        display: "none", color: '#ABABB0'
    },
    helperTextfutures: {
        background: '#DAF0E5',
        borderRadius: "10px",
        padding: '2em',
        marginLeft: "2em",
        marginTop: '4em',
        border: '1px solid black',
        color: "#337152"
    },
    topmargin: {
        marginTop: "3px"
    },
    iconAlign: {
        display: "flex",
        gap: "10px"
    },
    underhovertext: {
        color: "rgba(54,64,59,0.5)",
        fontSize: "12px",
        fontWeight: 3500,
        fontFamily: "Nunito",
    },
    donationdeading: {
        color: "#36403b",
        fontSize: "27px",
        fontWeight: 700,
        fontFamily: "Nunito",
    },
    amountWrapper: {
        display: "flex"
    },
    expensePadding: {
        marginTop: "7px",
        paddingBottom: "7px"
    },
    subheadingdonations: {
        color: "#36403b",
        fontSize: "24px",
        fontWeight: 700,
        fontFamily: "Nunito",
    },
    addMore1: {
        color: '#000',
        fontFamily: 'Nunito',
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 600
    },

    textfiledWrapper: {

        "& input": {
            borderWidth: "0.3px",
            padding: "11px 0px 11px 12px",

            border: "1px solid #grey"
        },
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
                borderWidth: "0.3px",
            },
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderLeft: 'none',
                borderRadius: "0px 8px 8px 0px",
                borderWidth: "0.3px",
            },
        },

    },
    ruppeslogoWrapper: {
        background: "#ddfef7",
        padding: '1em 2em 1em 1em',
        height: '42px',
        borderRadius: "8px 0px 0px 8px",
        display: "flex",
        alignItems: "center"
    },
    mainWrapper: {
        background: "#F4F6F5",
        borderRadius: "16px",
        margin: "4em 0 3em 1em",
        "@media(max-width:484px)": {
            margin: "0 0 0 1em",
        },

    },
    backgroundWrapper: {
        borderRadius: "16px 10px 10px 16px",
        background: "#FFF",
        marginTop: '2em',
        padding: '2em 2em 2em 0em',
        "@media(max-width:484px)": {
            padding: '2em 0 2em 0em',
        },
    },
    textAlignment: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: '2em 5em 0.5em 2em',
        gap: "50px",
        [theme.breakpoints.between('xs', 'sm')]: {
            display: "unset",
        },
    },

    boxwidth: {
        width: '30%',
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '100%',
        },
    },
    rupeessstyles: {
        color: "rgba(54,64,59,0.9)",
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Nunito",
    },
    selectdropDown: {
        maxWidth: '100%',
        width: "500px",
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            height: '45px',
            top: '4px'
        }


    },

    labelheading: {
        color: "rgba(54,64,59,0.7)",
        fontSize: "17px",
        fontWeight: 600,
        fontFamily: "Nunito",
        [theme.breakpoints.between('xs', 'sm')]: {
            marginTop: '3em',
        },
    },
    iconArrangmment: {
        marginTop: '1em',
        marginLeft: '2em',
        display: 'flex',
        gap: '10px',
        width: 'max-content'
    },
    buttonComponent: {
        background: "#337152",
        borderRadius: "5px",
        color: "#ffff",
        fontWeight: 600,
        fontFamily: "Nunito",
        padding: '0.5em 4em 0.5em 4em',
        margin: "2.5em 1em 4em 0",
        '&:hover': {
            background: "#337152",
        },
    },
    headingwrapeer: {
        marginLeft: '2em'

    },
    conditionalheading: {
        padding: "2em 0em 0 0em",
        display: "flex",
        alignItems: "center",
        gap: '4px',
        cursor: "pointer"
    },
    addnlGrid: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 8
    },
    deleteGrid: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    commonGrid: {
        padding: 10,
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem'
    },
})
export default withStyles(styles)(FutureOptions);
// Customizable Area End