import React from "react";
// Customizable Area Start
import {
    Box,
    RadioGroup,
    Radio,
    FormControlLabel,
    withStyles,
    styled,
    MenuItem,
    Select
} from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssessmenttestController, {
    Props,
} from "./AssessmenttestController";
import MainHeader from "../../../components/src/MainHeader.web";
import CustomInput from "../../../components/src/CustomInput.web";
// Customizable Area End

export default class CapitalGainCalc extends AssessmenttestController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    customBox = (input: any, minHeight: string = "") => {
        return (
            <div style={{ marginBottom: '10px', border: '1px solid #d3d3d3', padding: '2px 35px', width: '80%', height: minHeight == "" ? '50px' : "", minHeight: minHeight, display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', }}>
                {input}
            </div>
        )
    }

    customInput = (heading: string, name: string, type: string, onChange: any, value: string, disabled: boolean) => {
        return this.customBox(
            <>
                <h3>{heading}</h3>
                <CustomInput
                    name={name}
                    type={type}
                    onChange={onChange}
                    value={value}
                    placeholder={disabled ? "" : "Enter"}
                    disabled={disabled}
                    data-test-id={name}
                />
            </>
        )
    }
    renderSaleOfLand = () => {
        return (
            <>
                {this.customBox(
                    <>
                        <h3>Period of holding</h3>
                        <RadioGroup row style={webStyle.radioGroupWrapper} name="period_of_holding" onChange={(event, value) => this.handleRadioChangePeriod(value, "periodOfHolding")} value={this.state.periodOfHolding} data-test-id="saleOfLand">
                            <FormControlLabel style={webStyle.marginRightNone} value="More than 2 years" control={<StyledRadio />} label="More than 2 years" />
                            <FormControlLabel style={webStyle.marginRightNone} value="Less than 2 years" control={<StyledRadio />} label="Less than 2 years" />
                        </RadioGroup>
                    </>
                )}
                {this.customInput("Year of Sales", "YOS", "text", this.handleHRA_Change2, this.state.YOS, false)}
                {this.customInput("Sale Consideration", "saleC", "text", this.handleHRA_Change2, this.state.saleC, false)}
                {this.customInput("Transfer expenses", "transferExp", "text", this.handleHRA_Change2, this.state.transferExp, false)}
                {this.customInput("Cost of Acquisition", "COA", "text", this.handleHRA_Change2, this.state.COA, false)}
                {this.customInput("Cost of Improvement", "COI", "text", this.handleHRA_Change2, this.state.COI, false)}
                {this.customInput("Assessed Tax", "assessedTax", "text", this.handleHRA_Change2, this.state.assessedTax, true)}
            </>
        )
    }

    renderVirtualAssets = () => {
        return (
            <>
                {this.customInput("Total Sale Value", "totalCost", "text", this.handleHRA_Change2, this.state.totalCost, false)}
                {this.customInput("Total Cost", "saleValue", "text", this.handleHRA_Change2, this.state.saleValue, false)}
                {this.customInput("Assessed Tax", "assessedTax", "text", this.handleHRA_Change2, this.state.assessedTax, true)}
            </>
        )
    }

    renderSaleOfShares = () => {
        return (
            <>

                {this.customBox(
                    <>
                        <h3>Choose the type</h3>
                        <StyledSelect
                            IconComponent={arrowDownIcon}
                            MenuProps={{disableScrollLock:true}}
                            style={{ color: 'grey', }}
                            data-test-id="assessment"
                            disableUnderline
                            displayEmpty
                            value={this.state.assessmentYear}
                            name="AssessmentYear"
                            onChange={this.handleChangeSelectDropDown}
                            renderValue={this.state.assessmentYear !== "" ? undefined : () => "Select"}
                        >

                            <MenuItem className='menuItems' value={"2024-25"}>2024-25</MenuItem>
                            <MenuItem className='menuItems' value={"2023-24"}>2023-24</MenuItem>
                            <MenuItem className='menuItems' value={"2022-23"}>2022-23</MenuItem>
                        </StyledSelect>
                    </>
                )}
                {this.customBox(
                    <>
                        <h3>Period of holding</h3>
                        <RadioGroup row style={webStyle.radioGroupWrapper} name="period_of_holding" onChange={(event, value) => this.handleRadioChangePeriod(value, "periodOfHolding")} value={this.state.periodOfHolding} data-test-id="periodOfHolding">
                           
                            <FormControlLabel
                                value="More than 2 years"
                                control={<StyledRadio />}
                                style={webStyle.marginRightNone}
                                label={
                                    <div>
                                        Long Term
                                        <p style={{ fontSize: "14px", color: "#666", marginLeft: "30px", margin: "0px" }}>
                                            (Holding period of more than 12 months) : 15%
                                        </p>
                                    </div>}
                            />
                            <FormControlLabel
                                value="Less than 2 years"
                                control={<StyledRadio />}
                                style={webStyle.marginRightNone}
                                label={
                                    <div>
                                        Short Term
                                        <p style={{ fontSize: "14px", color: "#666", marginLeft: "30px", margin: "0px" }}>
                                            (Holding period of less than 12 months)
                                        </p>
                                    </div>}
                            />
                        </RadioGroup>
                    </>,
                    "98px"
                )}
                {this.customInput("Sale Value", "totalCost", "text", this.handleHRA_Change2, this.state.totalCost, false)}
                {this.customInput("Purchase Value", "saleValue", "text", this.handleHRA_Change2, this.state.saleValue, false)}
                {this.customInput("Assessed Tax", "assessedTax", "text", this.handleHRA_Change2, this.state.assessedTax, true)}
            </>
        )
    }
    renderPage = () => {
        let heading = "";
        let subHeading:string|undefined = undefined;
        let component;
        switch (this.state.capitalGainType) {
            case "SaleOfLand":
                heading = "Sale of Land and Building";
                component = this.renderSaleOfLand();
                break;
            case "VirtualAssets":
                heading = "Crypto/Virtual Digital Assests";
                subHeading = "(Cryptocurrencies,NFTs and such)";
                component = this.renderVirtualAssets();
                break;
            case "SaleOfShares":
                heading = "Sale of Shares & Securities";
                subHeading = "(Sale of Indian Shares, Mutual funds, ETFs, Bonds, Foreign Shares, ESOPs etc)";
                component = this.renderSaleOfShares();
                break;
        }
        return (
            <Box style={webStyle.mainbox1} >
                <div style={webStyle.div2}>
                    <div style={webStyle.div3} onClick={this.handlenavigation}>
                        <ArrowBackIosIcon style={webStyle.arrowColor} />
                        <h4 style={webStyle.backHome}>Back To Home</h4>
                    </div>
                    <h1 style={webStyle.headerCap}>
                        {heading}
                    {subHeading && <span style={webStyle.subHeading}>{subHeading}</span>}
                    </h1>
                </div>
                {component}
            </Box>
        )
    }


    render() {
        return (
            <>
                <div style={webStyle.mainDiv}>
                    <MainHeader handleLogout={this.handleLogout} handlemodalopen={this.handlemodalopen} data-aos='zoom-in' navigation={undefined} handleHeaderRoute={this.handleHeaderRoute} handledashboard={this.navigateToDashBoard} handledashprofile={this.handledashprofile} handleToLandingPage={this.handleToLandingPageTo} handleLogin={this.handleLoginBtn} type={""} />
                </div>
                <Box>
                    {this.renderPage()}
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px', marginTop: '50px', }} >
                        <button style={{ height: '45px', width: '170px', color: 'white', backgroundColor: '#347152', fontSize: '16px', margin: '10px', fontWeight: "bold", borderRadius: '4px', fontFamily: 'sans-serif' }}>Calculate</button>

                        <button style={{
                            backgroundColor: 'white',
                            height: '45px', width: '170px', color: '#347152',
                            border: '2px solid #347152', fontSize: '16px', margin: '10px',
                            fontWeight: 'bold', borderRadius: '4px', fontFamily: 'sans-serif'
                        }}
                        onClick={this.handleReset}
                        >Reset</button>
                    </div>
                </Box>
            </>
        );
    }
}

const webStyle = {
    mainDiv: {
        height: '100px',
        backgroundColor: '#347152',
        marginBottom: '50px'
    },
    mainbox1: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%', flexDirection:
            'column' as "column",
        alignItems: 'center'
    },
    div2: {
        marginBottom: '5px',
        width: '85%',
    },
    div3: {
        height: '20px',
        display: 'flex',
        alignItems: 'center',
    },
    backHome: {
        fontWeight: 'normal' as "normal",
        color: "#347152",
    },
    inputBox: {
        paddingLeft: '10px',
        width: '180px',
        height: '33px',
        border: '1px solid grey',
        borderRadius: '4px',
        fontSize: '14px',
    },
    arrowColor: {
        color: "#347152"
    },
    headerCap: {
        color:"#36403B",
        fontSize:"32px",
        fontWeight:"bold" as "bold"
    },
    subHeading: {
        fontSize:'22px',
        color:"#36403B",
        marginLeft:"5px",
        fontWeight:"normal" as "normal"
    },
    radioGroupWrapper: {
        gap: "20px"
    },
    marginRightNone: {
        marginRight: 0
    }
}

const StyledRadio = styled(Radio)({
    '&.Mui-checked': {
        color: 'rgb(52, 113, 82)',
    },
});
const StyledSelect = withStyles((theme) => ({
    root: {
        fontSize: '14px',
        width: '143px',
        padding: '5px',
        paddingLeft: '10px',
        border: '1px solid grey',
        borderRadius: '4px',
        height: '20px',
        paddingTop: '6px',
        '& .MuiSvgIcon-root': {
            color: "red !important",
            marginRight: '10px', 
        },
    },
}))(Select);
const arrowDownIcon = styled(ExpandMoreIcon)({
    marginRight: "10px"
})

// Customizable Area End
